import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HelmetWrapper from '../components/HelmetWrapper'
import DeliveryZone from '../components/DeliveryZone'
import { localizeString } from '../shared/functions'
import MapMarkerIcon from '../assets/icons/navigation/geo.svg'
// import ClockIcon from '../assets/icons/navigation/clock-grey.svg'
import ClockIcon from '../assets/icons/navigation/clock.svg'
import PhoneIcon from '../assets/icons/navigation/phone.svg'
import MailIcon from '../assets/icons/navigation/email.svg'

export default function ContactsPage({ data, isMobile = false }) {
  const { device, cities, navbar, restaurant, map } = data
  const { locale } = device
  const title = localizeString('Контакты','Contacts',locale)
  const { workingTime } = restaurant
  return (
    <>
      <HelmetWrapper
        title={title}
        phone={restaurant.phone}
      />
      <Header
        cities={cities}
        navbar={navbar}
        restaurant={restaurant}
        device={device}
        locale={locale}
        isMobile={isMobile}
      />
      <div className="contacts-page-container">
        <div className="contacts-page-title">
          <h1>{title}</h1>
        </div>
        <div className="contacts-page-body">
          <h2>{localizeString(restaurant.name,'Pizzaria address',locale)}</h2>
          <div className="contacts-page-list-item">
            <img src={MapMarkerIcon} className="icon" alt="" />
            <span>{restaurant.address}</span>
          </div>
          <div className="contacts-page-list-item">
            <img src={PhoneIcon} className="icon" alt="" />
            <span>{restaurant.phone}</span>
          </div>
          <div className="contacts-page-list-item">
            <img src={MailIcon} className="icon" alt="" />
            <span>hello@fridaypizza.ru</span>
          </div>
          <div className="divider" />
          <h2>{localizeString('Прием заказов','Working time',locale)}</h2>
          <div className="contacts-page-list-item">
            <img src={ClockIcon} className="icon" alt="" />
            <span>{workingTime.main === '24' ? '24 часа' : workingTime.main}</span>
          </div>
          <div className="divider" />
          <h2>{localizeString('Доставка','Delivery',locale)}</h2>
          <div className="contacts-page-list-item">
            <img src={ClockIcon} className="icon" alt="" />
            <span>{workingTime.delivery === '24' ? '24 часа' : workingTime.delivery}</span>
          </div>
          <div className="divider" />
          <h2>{localizeString('Самовывоз','Pickup',locale)}</h2>
          <div className="contacts-page-list-item">
            <img src={ClockIcon} className="icon" alt="" />
            <span>{workingTime.pickup === '24' ? '24 часа' : workingTime.pickup}</span>
          </div>
        </div>
      </div>
      <DeliveryZone
        device={device}
        isMobile={isMobile}
        restaurant={restaurant}
        data={map}
      />
      <Footer
        device={device}
        locale={locale}
        social={restaurant.social}
      />
    </>
  )
}
