import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import LeftIcon from '../assets/icons/navigation/arwor-left.svg'
import CloseIcon from '../assets/icons/navigation/close.svg'
import BurgerIcon from '../assets/icons/navigation/burger.svg'

export default function Navbar({
  buttonLeft,
  leftHandle,
  buttonRight,
  rightHandle,
  customClass
}) {
  const history = useHistory()
  const [leftButton, setLeftButton] = useState(null)
  const [rightButton, setRightButton] = useState(
    <div
      onClick={()=>{
        console.log('TOGGLE_MOBILE_MENU')
      }}
    >
      <img src={BurgerIcon} alt="" />
    </div>
  )

  useEffect(() => {
    switch (buttonLeft) {
      case 'back':
        setLeftButton(
          <div
            onClick={(e)=>{
              e.stopPropagation()
              leftHandle &&
              leftHandle()
              // history.goBack()
          }}
        >
          <img src={LeftIcon} alt="" />
        </div>
        )
        break;
      default:
        setLeftButton(null)
        break;
    }
  }, [buttonLeft])

  useEffect(() => {
    switch (buttonRight) {
      case 'burger':
        setRightButton(
          <div
            onClick={()=>{
              rightHandle &&
              rightHandle()
          }}
        >
          <img src={BurgerIcon} alt="" />
        </div>
        )
        break;
      case 'close':
        setRightButton(
          <div
            onClick={()=>{
              rightHandle &&
              rightHandle()
          }}
        >
          <img src={CloseIcon} alt="" />
        </div>
        )
        break;
      default:
        setRightButton(null)
        break;
    }
  }, [buttonRight])

  // if (!navbarVisible) return null

  let style = 'navbar'

  if (customClass) {
    style = style + ` ${customClass}`
  }

  return (
    <div className={style}>
      <div className="navbar-button-left">
        {leftButton}
      </div>
      <div className="navbar-button-right">
        {rightButton}
      </div>
    </div>
  )
}