import { gql } from '@apollo/client'

const sharedOrderString = `
  id
  orderNumber
  name
  type
`

export const GET_ORDER = gql`
  query Order( $id: String! ){
    order(
      id: $id
    ) {
      ... on PickupOrder {
        ${sharedOrderString}
      }
      ... on DeliveryOrder {
        ${sharedOrderString}
      }
    }
  }
`

export const NEW_ORDER = gql`
  mutation CreateOrder($input: OrderInput) {
    createOrder(newOrder: $input) {
      ... on PickupOrder {
        ${sharedOrderString}
      }
      ... on DeliveryOrder {
        ${sharedOrderString}
      }
    }
  }
`

export const LIST_STREETS = gql`
  query Streets( $cityId: ID!, $limit: Int! ){
    streets(
      filter: {
        cityId: $cityId
      },
      limit: $limit
    ) {
      name
      fullName
      suburb
    }
  }
`

export const SEARCH_STREETS = gql`
  query SuggestionsStreets( $cityId: ID!, $query: String!, $limit: Int! ){
    suggestionsStreets(
      query: $query,
      cityId: $cityId,
      limit: $limit
    ) {
      name
      fullName
      suburb
    }
  }
`

export const ADDRESS_GEOSUGGEST = gql`
  query AddressGeosuggest($query: String!, $cityId: ID!) {
    addressGeosuggest(query: $query, cityId: $cityId) {
      title
      subtitle
      position {
        latitude
        longitude
      }
      isStreet
      isHouse
    }
  }
`

export const COORDINATES_GEOCODE = gql`
  query CoordinatesGeocode($latitude: Float!, $longitude: Float!) {
    coordinatesGeocode(latitude: $latitude, longitude: $longitude) {
      title
      city
      street
      house
      coordinates {
        latitude
        longitude
      }
    }
  }
`

export const UPDATE_DEVICE_GDPR = gql`
  mutation UpdateDevice( $id: ID!, $gdprStatus: Boolean! ) {
    updateDevice( device: { id: $id, gdprStatus: $gdprStatus } ){
      gdprStatus
    }
  }
`

export const UPDATE_DEVICE_LOCALE = gql`
  mutation UpdateDevice( $id: ID!, $locale: String! ) {
    updateDevice( device: { id: $id, locale: $locale } ){
      locale
    }
  }
`

// changeDeliveryType
export const CHANGE_DELIVERY_TYPE = gql`
  mutation ChangeDeliveryType($id: ID, $isDelivery: Boolean!, $isPickup: Boolean!) {
    changeDeliveryType(id: $id, isDelivery: $isDelivery, isPickup: $isPickup){
      isDelivery
      isPickup
      subTotal {
        amount
        formatted
      }
      discount {
        amount
        formatted
      }
      grandTotal {
        amount
        formatted
      }
    }
  }
`

const sharedString = `
  items {
    productId
    selectedSize
    quantity
    price
    productType
  }
  totalItems
  subTotal {
    amount
    formatted
  }
  discount {
    amount
    formatted
  }
  grandTotal {
    amount
    formatted
  }
`

export const REMOVE_FROM_CART = gql`
  mutation RemoveFromCart($id: ID!, $rowId: Int!) {
    removeFromCart(id: $id, rowId: $rowId){
      isEmpty
      ${sharedString}
    }
  }
`

export const UPDATE_IN_CART = gql`
  mutation UpdateInCart($id: ID!, $rowId: Int!, $mode: String!) {
    updateInCart(id: $id, rowId: $rowId, mode: $mode){
      ${sharedString}
    }
  }
`

export const ADD_TO_CART = gql`
  mutation AddToCart($productId: ID!, $selectedSize: Int!, $price: Int!, $productType: String!, $quantity: Int!) {
    addToCart(
      newItem: {
        productId: $productId
        selectedSize: $selectedSize
        price: $price
        productType: $productType
        quantity: $quantity
      }
    ) {
      isEmpty
      ${sharedString}
    }
  }
`

export const CLEAR_CART_AND_CHANGE_CITY = gql`
  mutation ClearCustomerCartAndChangeCity( $deviceId: ID!, $cityId: String! ) {
    clearAllItemsInCart {
      isEmpty
      ${sharedString}
    }
    updateDevice( device: { id: $deviceId, cityId: $cityId } ){
      cityId
    }
  }
`

export const CLEAR_CART = gql`
  mutation ClearAllItemsInCart {
    clearAllItemsInCart {
      isEmpty
      ${sharedString}
    }
  }
`

export const UPDATE_DEVICE_CITY = gql`
    mutation UpdateDevice( $id: ID!, $cityId: String! ) {
    updateDevice( device: { id: $id, cityId: $cityId } ){
      cityId
    }
  }
`

export const GET_CART = gql`
  query {
    cart {
      id
      isEmpty
      isDelivery
      isPickup
      items {
        productId
        selectedSize
        quantity
      }
      totalItems
#     totalUniqueItems
      subTotal {
        amount
        formatted
      }
      discount {
        amount
      }
      grandTotal {
        amount
        formatted
      }
    }
  }
`

export const GET_CART_DESCRIPTION = gql`
  query CartDescription( $id: ID, $deliveryCoordinates: DeliveryCoordinatesInput ) {
    cartDescription( id: $id, deliveryCoordinates: $deliveryCoordinates ) {
      totalItems
      subTotal {
        amount
        formatted
      }
      discount {
        amount
      }
      grandTotal {
        amount
        formatted
      }
    }
  }
`

export const INITIAL = gql`
  query Initial ( $city: String! ) {
    device {
      id
      locale
      gdprStatus
      cityId
    }
    cities(filter: { visibility: true }) {
      id
      name
      alias
    }
    banners(city: $city) {
      ... on BannerWeb {
        priority
        desktop {
          url
          height
          width
        }
        mobile {
          url
          height
          width
        }
      }
    }
    navbar(city: $city) {
      id
      name
      alias
      elementId
    }
    products(city: $city, filter: { available: true }) {
      ... on Pizza {
        id
        type
        title
        alias
        minPrice
        isCalzone
        tags
        # contains {
        #   name
        # }
        containsFormatted
        sizes {
          diameter
          capacity
          price
          image(width: 584) {
            url
          }
        }
      }
      ... on Sushi {
        id
        type
        title
        alias
        minPrice
        # contains {
        #   name
        # }
        containsFormatted
        sizes {
          amount
          capacity
          price
          image(width: 584) {
            url
          }
        }
      }
      ... on Snack {
        id
        type
        title
        alias
        minPrice
        sizes {
          capacity
          amount
          price
          image(width: 584) {
            url
          }
        }
      }
      ... on Salad {
        id
        type
        title
        alias
        minPrice
        sizes {
          capacity
          price
          image(width: 584) {
            url
          }
        }
      }
      ... on Drink {
        id
        type
        title
        alias
        minPrice
        sizes {
          capacity
          price
          image(width: 584) {
            url
          }
        }
      }
      ... on Sauce {
        id
        type
        title
        alias
        minPrice
        sizes {
          capacity
          price
          image(width: 584) {
            url
          }
        }
      }
    }
    restaurants {
      id
      name
      address
      phone
      city {
        id
        name
        alias
      }
      social {
        icon
        url
      }
      workingTime {
        main
        delivery
        pickup
      }
    }
    map(city: $city) {
      center {
        latitude
        longitude
      }
      markers {
        id
        title
        geolocation {
          latitude
          longitude
        }
      }
      zones {
        id
        title
        alias
        labelPosition
        coordinates
        cost {
          amount
        }
        style {
          background {
            color
            opacity
          }
          line {
            color
            opacity
          }
        }
      }
    }
  }
`

export const PRE_INITIAL = gql`
  query {
    device {
      id
      locale
      gdprStatus
      cityId
    }
    cart {
      id
    }
    cities(filter: { visibility: true }) {
      id
      name
      alias
    }
  }
`

/*===========================================
=============== Delivery cost ==============
===========================================*/

export const GET_DELIVERY_COST = gql`
  query GetDeliveryCost( $coordinates: AddressCoordinatesInput!, $cityId: String! ) {
    getDeliveryCost( coordinates: $coordinates, cityId: $cityId ) {
      title
      cost {
        amount
        formatted
      }
      zone {
        id
        name
        restaurantId
      }
      isAvailable
      errors {
        code
        message
      }
    }
  }
`

/*===========================================
========== Delivery & Pickup times ==========
===========================================*/

export const GET_DELIVERY_TIME = gql`
  query GetDeliveryTime( $cityId: String! ) {
    getDeliveryTime( cityId: $cityId ) {
      data {
        id
        label
        value
        timeIntervals
      }
      errors {
        message
      }
    }
  }
`

export const GET_PICKUP_TIME = gql`
  query GetPickupTime( $restaurantId: String! ) {
    getPickupTime( restaurantId: $restaurantId ) {
      data {
        id
        label
        value
        timeIntervals
      }
      errors {
        message
      }
    }
  }
`
