import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import leftArrow from '../assets/icons/navigation/arwor-left-grey.svg'
import rightArrow from '../assets/icons/navigation/arwor-right-grey.svg'

export default function ImgSlider({ isMobile, banners }) {
  const settings = {
    dots: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <div className="container">
      <Slider {...settings}>
        {
          banners.map(
            slide => 
              <Slide
                key={slide.priority}
                isMobile={isMobile}
                slide={slide}
              />
          )
        }
      </Slider>
    </div>
  )
}

const Slide = ({ isMobile, slide }) => {
  const { desktop, mobile } = slide
  if (isMobile) {
    return (
      <div className="wrap-slide">
        <img src={mobile.url.replace('.webp','.jpg') + '?h=' + mobile.height} alt="" />
      </div>
    )
  }
  if (!isMobile) {
    return (
      <div className="wrap-slide">
        <img src={desktop.url.replace('.webp','.jpg') + '?h=' + desktop.height} alt="" />
      </div>
    )
  }
  return null
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slider-prev-arrow"
      // className={className}
      // style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      <img className="slider-arrow-icon" src={leftArrow} alt="" />
    </div>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slider-next-arrow"
      // className={className}
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <img className="slider-arrow-icon" src={rightArrow} alt="" />
    </div>
  )
}

// <div className="wrap-slide">
//   <img src={`/static/images/banners/02.jpg`} alt="" />
// </div>
// <div className="wrap-slide">
//   <img src={`/static/images/banners/03.svg`} alt="" />
// </div>
// <div className="wrap-slide">
//   <img src={`/static/images/banners/01.svg`} alt="" />
// </div>