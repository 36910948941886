import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { localizeString } from '../shared/functions'
import Button from './Button'

export default function MobileMenu({ isVisible, device, cities, restaurant, defaultSelectedCity, handleCity, handleLanguage, avaliableLanguages }) {
  const { locale } = device
  const { city: cityAlias } = useParams()
  const currentCityName = cities.find(c => c.alias === cityAlias)['name']
  const currentLocaleIndex = locale === "ru" ? 0 : 1 || 0
  const [isVisibleCityList, changeVisibleCityList] = useState(false)
  const [isVisibleLangList, changeVisibleLangList] = useState(false)

  const langList = [
    { name: 'Русский', icon: 'ru' },
    { name: 'English', icon: 'en' }
  ]

  const selectNewLang = (index) => {
    changeVisibleLangList(false)
    handleLanguage({ index })
  }

  const hadnleChangeCity = () => {
    changeVisibleCityList(true)
  }

  const selectNewCity = (index) => {
    changeVisibleCityList(false)
    handleCity &&
    handleCity({ index })
  }

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }

    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [isVisible])

  if (isVisibleLangList) return (
    <div className={`mobile-menu-container ${isVisible ? 'active' : ''}`}>
      <h2 className="mobile-menu-line-title">{localizeString('Выберете язык', 'Select language', locale)}</h2>
      <div className="mobile-menu-cities-list">
        <ul>
          {
            langList.map((langItem, index) => {
              if (index === currentLocaleIndex) {
                return <li key={index} className={`lang-icon-${langItem.icon} active`} onClick={() => changeVisibleLangList(false)}>{langItem.name}</li>
              }

              return <li key={index} className={`lang-icon-${langItem.icon}`} onClick={() => selectNewLang(index)}>{langItem.name}</li>
            })
          }
        </ul>
      </div>
    </div>
  )

  if (isVisibleCityList) return (
    <div className={`mobile-menu-container ${isVisible ? 'active' : ''}`}>
      <h2 className="mobile-menu-line-title">{localizeString('Выберете свой город', 'Select your city', locale)}</h2>
      <div className="mobile-menu-cities-list">
        <ul>
          {
            cities.map((cityItem, index) => {
              if (index === defaultSelectedCity) {
                return <li key={cityItem.id} className="active" onClick={() => changeVisibleCityList(false)}>{cityItem.name}</li>
              }

              return <li key={cityItem.id} onClick={() => selectNewCity(index)}>{cityItem.name}</li>
            })
          }
        </ul>
      </div>
    </div>
  )

  return (
    <div className={`mobile-menu-container ${isVisible ? 'active' : ''}`}>
      <div className="mobile-menu-lines">
        <div className="mobile-menu-line mobile-menu-line-city"
          onClick={hadnleChangeCity}
        >
          <h2>{currentCityName}</h2>
        </div>
        <div className="mobile-menu-line mobile-menu-line-clock">
          <h2>{restaurant.workingTime.main === '24' ? '24 часа' : restaurant.workingTime.main}</h2>
        </div>
        <div className="mobile-menu-line mobile-menu-line-phone">
          <h2><a href={`tel:${restaurant.phone}`} rel="noopener noreferrer">{restaurant.phone}</a></h2>
        </div>
      </div>
      <div className="mobile-menu-list">
        <ul>
          {
            // state.navbarList.map((navbarItem) => <li key={navbarItem.id}>{navbarItem.name}</li>)
          }
          <li><Link to={`/${cityAlias}/about-us`}>{localizeString('О компании','About Us',locale)}</Link></li>
          <li><Link to={`/${cityAlias}/contacts`}>{localizeString('Контакты','Contacts',locale)}</Link></li>
        </ul>
      </div>
      <div className="mobile-menu-bottom">
        <Button
          customStyle='button-dropdown-modern'
          handle={() => changeVisibleLangList(true)}
          title={avaliableLanguages[currentLocaleIndex]}
        />
      </div>
    </div>
  )
}

// <div className="mobile-menu-lines">
//   <div className={`mobile-menu-line mobile-menu-line-${langList[currentLocaleIndex]['icon']}`}
//     onClick={() => changeVisibleLangList(true)}
//   >
//     <h2>{localizeString('Русский', 'English', locale)}</h2>
//   </div>
// </div>