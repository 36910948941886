import React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_DELIVERY_COST } from '../queries'

export default function useDeliveryCost() {
	const [getDeliveryCost, { loading, data }] = useLazyQuery(GET_DELIVERY_COST, {
		fetchPolicy: 'no-cache',
	})

	const handle = (coordinates, cityId) => {
		getDeliveryCost({
			variables: {
				coordinates,
				cityId,
			}
		})
	}

	return [loading, data?.getDeliveryCost, handle]
}