import React from 'react'
import Button from './Button'
import { useHistory, useParams } from "react-router-dom"
import { useQuery } from '@apollo/client'
import { GET_ORDER } from '../queries'
import { randomIntFromInterval } from '../shared/functions'

export default function OrderSuccess({ device }) {
  const { locale } = device
  const history = useHistory()
  const { orderId, city: cityAlias } = useParams()
  const { data } = useQuery(GET_ORDER, {
    variables: { id: orderId }
  })

  if (!data) return null
  //const orderNumber = randomIntFromInterval(36,97) // fake order number for test

  return (
    <div className="cart-container">
      {
        data &&
        data.order &&
        <>
          <div className="cart-title empty">
            <h1>{`${data.order.name}, спасибо за выбор Пятница Пицца!`}</h1>
            <h2>{`Заказ #${data.order.orderNumber}`}</h2>
          </div>
          <div className="cart-items-list">
            <img src="/static/images/illustrations/order_confirm_1.svg" className="empty-cart-image" alt="" />
            <div className="empty-cart-text-block mb-2">
              <p>Обращаем Ваше внимание, что заказ принимается в работу с момента окончания разговора с оператором колл-центра, подтверждающим заказ, либо получения СМС-уведомления.</p>
              <p>Время доставки также отсчитывается с момента принятия заказа в работу.</p>
            </div>
          </div>
          <div className="cart-footer empty">
            <div className="cart-footer-control">
              <Button customStyle="cart-footer-button" handle={
                () => { history.push(`/${cityAlias}`) }
              }>На главную</Button>
            </div>
          </div>
        </>
      }
    </div>
  )
  // <div className="empty-cart-text-block mb-2">
  //   <p>Обращаем Ваше внимание, что заказ принимается в работу с момента окончания разговора с оператором колл-центра, подтверждающим заказ, либо получения СМС-уведомления. </p>
  // </div>
}
