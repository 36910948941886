import React, { useEffect } from 'react'
import { hydrate } from 'react-dom'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import App from './App'
import cache from './shared/storage'

const isDev = process.env.NODE_ENV === 'development' ? true : false

// Version with local state management
const client = new ApolloClient({
  uri: isDev ? 'http://localhost:3000/api' : 'https://fridaypizza.ru/api',
  cache,
  ssrForceFetchDelay: 100, // in milliseconds
  credentials: 'same-origin'
})

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

hydrate(
  <ApolloProvider client={client}>
    <CookiesProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App isMobile={false} />
      </BrowserRouter>
    </CookiesProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
