import React, { useState } from 'react'

export default function TextArea({ 
  name = '', 
  placeholder = '',
  width = null,
  customStyle,
  defaultValue = '',
  readOnly = false,
  maxLength = null,
  rows = 2,
  handle
}) {
  const [value, setValue] = useState(defaultValue)
  
  const changeHandler = (event) => {
    let value = event.target.value

    setValue(value)
    handle && 
    handle({
      name,
      value: value
    })
  }
  
  const styles = {}

  if (width) {
    styles.width = width
  }

  return (
    <div 
      className={
        customStyle 
        ? `main-textarea-wrapper ${customStyle} ${value !== '' ? 'hide-span' : ''}` 
        : `main-textarea-wrapper ${value !== '' ? 'hide-span' : ''}`
      }
      style={styles}
    >
      <textarea
        className="main-textarea"
        name={name}
        value={value}
        onChange={changeHandler}
        readOnly={readOnly}
        maxLength={maxLength}
        rows={rows}
      />
      {
        placeholder &&
        <span>{placeholder}</span>
      }
    </div>
  )
}
