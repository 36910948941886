import React, { useState, useEffect, useRef } from 'react'
import Button from './Button'

export default function DropdownModern({ 
  defaultSelected = 0,
  largeButton = false, 
  largeList = false,
  customClass = "", 
  buttonIcon = "", 
  customClassButton = "main", 
  customClassDropdown = "",
  name = '', 
  handle, 
  items 
}) {
  const [visibleDropdown,setVisibleDropdown] = useState(false)
  const [selectedItem,setSelectedItem] = useState(defaultSelected)

  const handleDropdown = () => {
    setVisibleDropdown(visibleDropdown ? false : true)
  }

  const selectHandler = (index) => {
    setVisibleDropdown(false)

    // if we clicked on not selected item
    if (selectedItem !== index) {
      setSelectedItem(index)
      handle &&
      handle({ name, index })
    }
  }

  const wrapperRef = useRef(null)
  useOutsideObserver(wrapperRef)

  function useOutsideObserver(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisibleDropdown(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      };
    }, [ref])
  }

  if (items.length > 4) {
    largeList = true
  }

  return (
    <div 
      ref={wrapperRef}
      className={`dropdown-modern-wrapper ${customClass}`}
    >
      <Button 
        customStyle={
          visibleDropdown 
            ? `${largeButton ? 'button-dropdown-modern' : ''} ${customClassButton} active` 
            : `${largeButton ? 'button-dropdown-modern' : ''} ${customClassButton}`
        }
        handle={handleDropdown}
      >{buttonIcon}{items[selectedItem]}</Button>
      <div className={
        visibleDropdown 
          ? `dropdown-modern ${largeList ? "list-large" : ""} ${customClassDropdown} open` 
          : `dropdown-modern ${largeList ? "list-large" : ""} ${customClassDropdown} closed`
      }>
        <ul className={`dropdown-modern-list ${largeList ? "list-large" : ""}`}>
          {
            items.map((item, index) => {
              return (
                <li key={index} className={selectedItem === index ? 'active' : ''} onClick={selectHandler.bind(null, index)}>{item}</li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}