import React from 'react'

export const UserAgreement = () => {
  return <p>Пользовательское соглашение</p>
}

export const PrivacyPolicy = () => {
  return <p>Политика обработки персональных данных (Privacy policy)</p>
}

export const PoliticsOfPrivacy = () => {
  return <>
    <p>Предоставляя свои персональные данные (заполняя форму обратной связи) на сайте www.fridaypizza.ru, я подтверждаю своё совершеннолетие и, действуя своей волей и в своем интересе, в соответствии с Федеральным законом от 27.07.2006 № 152‑ФЗ «О персональных данных», даю согласие Индивидуальному Предпринимателю Михайлову Павлу Юрьевичу, юридический адрес: 678170, г. Мирный, улица Советская, дом 13/7, кв. 10; (далее – Продавец) на обработку моих персональных данных. Мои персональные данные были указаны мной при заполнении формы ввода информации при заполнении формы обратной связи.</p>
    <p>Цели обработки персональных данных:</p>
    <ul>
      <li>оказание услуг общественного питания;</li>
      <li>доставка мне товаров, продукции, в том числе третьими лицами - по поручению Продавца;</li>
      <li>вступление в Программу Лояльности "Пятница Бонус";</li>
      <li>получение информационных и рекламных sms и email сообщений;</li>
      <li>участие в маркетинговых акциях, мероприятиях и исследованиях, проводимых Продавцом, либо по его поручению третьими лицами;</li>
      <li>оценка и улучшение качества работы, услуг Продавца, разработка новых блюд, услуг;</li>
      <li>статистические исследования, в том числе касающиеся работы Продавца, качества продукции, качества обслуживания, в том числе проводимые третьими лицами - по поручению Продавца;</li>
    </ul>
    <p>Перечень персональных данных: имя, адрес места жительства (доставки продукции, товаров), контактный номер телефона, адрес электронной почты и другая предоставляемая мной информация.</p>
    <p>Перечень действий с персональными данными: сбор (в том числе от третьих лиц), запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ, в том числе третьим лицам), обезличивание, блокирование, удаление, уничтожение с использованием средств автоматизации или без использования таких средств.</p>
    <p>В целях информационного обеспечения даю согласие считать мои персональные данные (имя, адрес места жительства, контактный номер телефона, адрес электронной почты) общедоступными в рамках сети предприятий общественного питания, работающих под товарным знаком «Пятница Пицца».</p>
    <p>Данное мной согласие действует в течение неограниченного периода времени и может быть отозвано мною в любое время на основании моего заявления, направленного на электронный адрес hello@fridaypizza.ru либо почтовым отправлением по адресу (месту нахождения) Продавца.</p>
  </>
}