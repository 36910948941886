import React, { useState } from 'react'
import { formatCapacity, localizeString } from '../shared/functions'
import { Link, useLocation, useParams } from 'react-router-dom'
import Button from './Button'
import ProductSize from './ProductSize'
import { useQuery, useMutation } from '@apollo/client'
import { GET_CART, ADD_TO_CART, UPDATE_IN_CART, REMOVE_FROM_CART } from '../queries'
import { CDN_URL } from '../shared/constants'
import VeganIcon from '../assets/icons/tags/vegan.png'
import SpicyIcon from '../assets/icons/tags/spicy.png'

const updateCacheAddToCart = (cache, { data: { addToCart } }) => {
  const { items, isEmpty, totalItems, subTotal, discount, grandTotal } = addToCart
  const { cart: initialCart} = cache.readQuery({ query: GET_CART })
  const newCart = {
    ...initialCart,
    items,
    isEmpty,
    totalItems,
    subTotal,
    discount,
    grandTotal
  }

  cache.writeQuery({
    query: GET_CART,
    data: {
      cart: newCart
    }
  })
}
const updateCacheUpdateInCart = (cache, { data: { updateInCart } }) => {
  const { cart: prevCart} = cache.readQuery({ query: GET_CART })
  const { items, totalItems, subTotal, discount, grandTotal } = updateInCart
  const newCart = {
    ...prevCart,
    items,
    totalItems,
    subTotal,
    discount,
    grandTotal
  }

  cache.writeQuery({
    query: GET_CART,
    data: {
      cart: newCart
    }
  })
}
const updateCacheRemoveFromCart = (cache, { data: { removeFromCart } }) => {
  const { cart: prevCart} = cache.readQuery({ query: GET_CART })
  const { items, isEmpty, totalItems, subTotal, discount, grandTotal } = removeFromCart
  const newCart = {
    ...prevCart,
    items,
    isEmpty,
    totalItems,
    subTotal,
    discount,
    grandTotal
  }

  cache.writeQuery({
    query: GET_CART,
    data: {
      cart: newCart
    }
  })
}

const PizzaItem = ({ product, locale, cartItems, cartId }) => {
  const [addToCart] = useMutation(ADD_TO_CART, {
    update: updateCacheAddToCart
  })
  const [updateInCart] = useMutation(UPDATE_IN_CART, { 
    update: updateCacheUpdateInCart
  })
  const [removeFromCart] = useMutation(REMOVE_FROM_CART, { 
    update: updateCacheRemoveFromCart
  })
  const [selectedSize, selectSize] = useState(0)
  const foundItem = cartItems.find(item => (item.productId === product.id && item.selectedSize === selectedSize)),
        foundIndex = cartItems.findIndex(item => (item.productId === product.id && item.selectedSize === selectedSize))

  const handleClick = (e) => {
    e.preventDefault()
    addToCart(
      {
        variables: {
          productId: product.id,
          selectedSize: selectedSize,
          price: product.sizes[selectedSize]['price'],
          productType: product.type,
          quantity: 1
        },
        // refetchQueries: [
        //   { query: GET_CART },
        //   // { query: GET_CART_DESCRIPTION }
        // ],
        // update: (cache, { data: { addToCart } }) => {

        //   const { cart: prevCart} = cache.readQuery({ query: GET_CART })
        //   const { addToCart } = data
        //   const { items, totalItems, subTotal, discount, grandTotal } = addToCart

        //   console.log('data: ',data, addToCart)

        //   const newCart = {
        //     ...prevCart,
        //     items,
        //     totalItems,
        //     subTotal,
        //     discount,
        //     grandTotal
        //   }

        //   console.log('newCart: ',newCart)
        //   cache.writeQuery({
        //     query: GET_CART,
        //     data: {
        //       cart: newCart
        //     }
        //   })

        //   // const cart = cache.readQuery({ query: GET_CART })

        //   // cart.items = [...cart.items, addToCart]
        //   // cache.writeQuery({ query: GET_CART }, cart)
        // }
      }
    )
  }

  const handlerIncrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'plus'
        }
      }
    )
  }

  const handlerDecrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'minus'
        }
      }
    )
  }

  const handlerRemove = (e) => {
    e.preventDefault()
    removeFromCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex
        }
      }
    )
  }

  const handleSize = (index) => {
    selectSize(index)
  }

  const imageURL = product.sizes[selectedSize]['image']['url']
    .replace('.webp','.jpg') + '?w=' + 256

  return <div className="menu-item-pizza">
    <div className="product-image">
      <img src={imageURL} alt="" />
    </div>
    <div className="product-description">
      <h3 className="product-title">
        {product.title}
        {product.tags &&
          product.tags.map(tag => {
          if (tag === 'VEGAN') return <span key={tag} className="product-tag-vegan">
            <img src={VeganIcon} alt="Вегетарианская" width={18} height={18} />
          </span>
          if (tag === 'SPICY') return <span key={tag} className="product-tag-spicy">
            <img src={SpicyIcon} alt="Острая" width={18} height={18} />
          </span>
        })}
        {
          product.isCalzone &&
          <span className="product-calzone">
            {localizeString(
              ' (закрытая пицца)',
              ' (calzone)',
              locale
            )}
          </span>
        }
      </h3>
      <p className="product-contains">
        { product.containsFormatted }
      </p>
      <div className="product-sizes">
        <ul>
        {
          product.sizes.map((size,index) => <ProductSize 
            key={index}
            index={index}
            locale={locale}
            type={product.type}
            size={size}
            selectedSize={selectedSize}
            handle={handleSize}
          />)
        }
        </ul>
      </div>
    </div>
    <div className="product-management">
      <div className="product-price">
        {`${product.sizes[selectedSize]['price']*(foundItem?.quantity || 1)}`}
        <span>{localizeString('руб.','rub.',locale)}</span>
      </div>
      {
        foundItem
          ? <div className="product-management-controls">
              {
                foundItem.quantity > 1
                  ? <button
                      className="count-control-button button-minus"
                      onClick={handlerDecrease}>
                      {/* <img src={`${CDN_URL}/static/icons/button-minus.svg`} alt="" /> */}
                      <img src={`/static/icons/button-minus.svg`} alt="" />
                    </button>
                  : <button
                      className="count-control-button button-remove"
                      onClick={handlerRemove}>
                      {/* <img src={`${CDN_URL}/static/icons/button-remove.svg`} alt="" /> */}
                      <img src={`/static/icons/button-minus.svg`} alt="" />
                    </button>
              }
              <span className="count-control-value">{foundItem.quantity}</span>
              <button
                className="count-control-button button-plus"
                onClick={handlerIncrease}>
                {/* <img src={`${CDN_URL}/static/icons/button-plus.svg`} alt="" /> */}
                <img src={`/static/icons/button-plus.svg`} alt="" />
              </button>
            </div>
          : <Button
              customStyle="branded"
              handle={handleClick}
              title={localizeString('В корзину','Add to cart',locale)}
            />
      }
    </div>
    {/* <div style={{ position: 'relative'}}>
      <Button
        customStyle="product-button branded"
        handle={handleClick}
        // style={{maxWidth: 11+'rem'}}
      >{`${product.sizes[selectedSize]['price']} ${localizeString('руб.','rub.',locale)}`}</Button>
    </div> */}
  </div>
}

const SnackItem = ({ product, cartItems, locale, cartId }) => {
  const [addToCart] = useMutation(ADD_TO_CART, {
    update: updateCacheAddToCart
  })
  const [updateInCart] = useMutation(UPDATE_IN_CART, { 
    update: updateCacheUpdateInCart
  })
  const [removeFromCart] = useMutation(REMOVE_FROM_CART, { 
    update: updateCacheRemoveFromCart
  })
  const [selectedSize, selectSize] = useState(0)
  const foundItem = cartItems.find(item => (item.productId === product.id && item.selectedSize === selectedSize)),
        foundIndex = cartItems.findIndex(item => (item.productId === product.id && item.selectedSize === selectedSize))

  const handleClick = (e) => {
    e.preventDefault()
    addToCart(
      { 
        variables: {
          productId: product.id,
          selectedSize: selectedSize,
          price: product.sizes[selectedSize]['price'],
          productType: 'snack',
          quantity: 1
        }
      }
    )
  }

  const handlerIncrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'plus'
        }
      }
    )
  }

  const handlerDecrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'minus'
        }
      }
    )
  }

  const handlerRemove = (e) => {
    e.preventDefault()
    removeFromCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex
        }
      }
    )
  }

  const imageURL = product.sizes[selectedSize]['image']['url'] + '?w=' + 256

  return (
    <div className="menu-item">
      <div className="product-image">
        <img src={imageURL} alt="" />
      </div>
      <div className="product-description">
        <h3 className="product-title">{product.title}</h3>
        <div className="product-sizes">
          <ul>
            {
              product.sizes.map((size,index)=> {
                return (
                  <li 
                    key={index}
                    className={selectedSize === index ? 'active' : ''}
                    onClick={()=>selectSize(index)}
                  >
                    {
                      size.capacity != 0 &&
                      size.capacity + ' ' + localizeString('грамм','grams',locale)
                    }
                    {
                      size.amount != 0 &&
                      size.amount + ' ' + localizeString('штук','pieces',locale)
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      <div className="product-management">
        <div className="product-price">
          {`${product.sizes[selectedSize]['price']*(foundItem?.quantity || 1)}`}
          <span>{localizeString('руб.','rub.',locale)}</span>
        </div>
        {
          foundItem
            ? <div className="product-management-controls">
                {
                  foundItem.quantity > 1
                    ? <button
                        className="count-control-button button-minus"
                        onClick={handlerDecrease}>
                        <img src={`/static/icons/button-minus.svg`} alt="" />
                      </button>
                    : <button
                        className="count-control-button button-remove"
                        onClick={handlerRemove}>
                        <img src={`/static/icons/button-minus.svg`} alt="" />
                      </button>
                }
                <span className="count-control-value">{foundItem.quantity}</span>
                <button
                  className="count-control-button button-plus"
                  onClick={handlerIncrease}>
                  <img src={`/static/icons/button-plus.svg`} alt="" />
                </button>
              </div>
            : <Button
                customStyle="branded"
                handle={handleClick}
                title={localizeString('В корзину','Add to cart',locale)}
              />
        }
      </div>
    </div>
  )
}

const SaladItem = ({ product, cartItems, locale, cartId }) => {
  const [addToCart] = useMutation(ADD_TO_CART, {
    update: updateCacheAddToCart
  })
  const [updateInCart] = useMutation(UPDATE_IN_CART, { 
    update: updateCacheUpdateInCart
  })
  const [removeFromCart] = useMutation(REMOVE_FROM_CART, { 
    update: updateCacheRemoveFromCart
  })
  const [selectedSize, selectSize] = useState(0)
  const foundItem = cartItems.find(item => (item.productId === product.id && item.selectedSize === selectedSize)),
        foundIndex = cartItems.findIndex(item => (item.productId === product.id && item.selectedSize === selectedSize))

  const handleClick = (e) => {
    e.preventDefault()
    addToCart(
      { 
        variables: {
          productId: product.id,
          selectedSize: selectedSize,
          price: product.sizes[selectedSize]['price'],
          productType: 'salad',
          quantity: 1
        }
      }
    )
  }

  const handlerIncrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'plus'
        }
      }
    )
  }

  const handlerDecrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'minus'
        }
      }
    )
  }

  const handlerRemove = (e) => {
    e.preventDefault()
    removeFromCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex
        }
      }
    )
  }

  const imageURL = product.sizes[selectedSize]['image']['url'] + '?w=' + 256

  return (
    <div className="menu-item">
      <div className="product-image">
        <img src={imageURL} alt="" />
      </div>
      <div className="product-description">
        <h3 className="product-title">{product.title}</h3>
        <div className="product-sizes">
          <ul>
            {
              product.sizes.map((size,index)=> {
                return (
                  <li 
                    key={index}
                    className={selectedSize === index ? 'active' : ''}
                    onClick={()=>selectSize(index)}
                  >
                    {
                      size.capacity &&
                      size.capacity + ' ' + localizeString('грамм','grams',locale)
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      <div className="product-management">
        <div className="product-price">
          {`${product.sizes[selectedSize]['price']*(foundItem?.quantity || 1)}`}
          <span>{localizeString('руб.','rub.',locale)}</span>
        </div>
        {
          foundItem
            ? <div className="product-management-controls">
                {
                  foundItem.quantity > 1
                    ? <button
                        className="count-control-button button-minus"
                        onClick={handlerDecrease}>
                        <img src={`/static/icons/button-minus.svg`} alt="" />
                      </button>
                    : <button
                        className="count-control-button button-remove"
                        onClick={handlerRemove}>
                        <img src={`/static/icons/button-minus.svg`} alt="" />
                      </button>
                }
                <span className="count-control-value">{foundItem.quantity}</span>
                <button
                  className="count-control-button button-plus"
                  onClick={handlerIncrease}>
                  <img src={`/static/icons/button-plus.svg`} alt="" />
                </button>
              </div>
            : <Button
                customStyle="branded"
                handle={handleClick}
                title={localizeString('В корзину','Add to cart',locale)}
              />
        }
      </div>
    </div>
  )
}

const DrinkItem = ({ product, cartItems, locale, cartId }) => {
  const [addToCart] = useMutation(ADD_TO_CART, {
    update: updateCacheAddToCart
  })
  const [updateInCart] = useMutation(UPDATE_IN_CART, { 
    update: updateCacheUpdateInCart
  })
  const [removeFromCart] = useMutation(REMOVE_FROM_CART, { 
    update: updateCacheRemoveFromCart
  })
  const [selectedSize, selectSize] = useState(0)
  const foundItem = cartItems.find(item => (item.productId === product.id && item.selectedSize === selectedSize)),
        foundIndex = cartItems.findIndex(item => (item.productId === product.id && item.selectedSize === selectedSize))
  
  const handleClick = (e) => {
    e.preventDefault()
    addToCart(
      { 
        variables: {
          productId: product.id,
          selectedSize: selectedSize,
          price: product.sizes[selectedSize]['price'],
          productType: 'drink',
          quantity: 1
        }
      }
    )
  }

  const handlerIncrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'plus'
        }
      }
    )
  }

  const handlerDecrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'minus'
        }
      }
    )
  }

  const handlerRemove = (e) => {
    e.preventDefault()
    removeFromCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex
        }
      }
    )
  }

  const imageURL = product.sizes[selectedSize]['image']['url']
    .replace('.webp','.jpg') + '?w=' + 256

  return <div className="menu-item">
    <div className="product-image">
      <img src={imageURL} alt="" />
    </div>
    <div className="product-description">
      <h3 className="product-title">{product.title}</h3>
      <div className="product-sizes">
        <ul>
          {
            product.sizes.map((size,index)=> {
              return <li 
                       key={index}
                       className={selectedSize === index ? 'active' : ''}
                       onClick={()=>selectSize(index)}
                     >{formatCapacity(size.capacity, locale)}</li>
            })
          }
        </ul>
      </div>
    </div>
    <div className="product-management">
      <div className="product-price">
        {`${product.sizes[selectedSize]['price']*(foundItem?.quantity || 1)}`}
        <span>{localizeString('руб.','rub.',locale)}</span>
      </div>
      {
        foundItem
          ? <div className="product-management-controls">
              {
                foundItem.quantity > 1
                  ? <button
                      className="count-control-button button-minus"
                      onClick={handlerDecrease}>
                      {/* <img src={`${CDN_URL}/static/icons/button-minus.svg`} alt="" /> */}
                      <img src={`/static/icons/button-minus.svg`} alt="" />
                    </button>
                  : <button
                      className="count-control-button button-remove"
                      onClick={handlerRemove}>
                      {/* <img src={`${CDN_URL}/static/icons/button-remove.svg`} alt="" /> */}
                      <img src={`/static/icons/button-minus.svg`} alt="" />
                    </button>
              }
              <span className="count-control-value">{foundItem.quantity}</span>
              <button
                className="count-control-button button-plus"
                onClick={handlerIncrease}>
                {/* <img src={`${CDN_URL}/static/icons/button-plus.svg`} alt="" /> */}
                <img src={`/static/icons/button-plus.svg`} alt="" />
              </button>
            </div>
          : <Button
              customStyle="branded"
              handle={handleClick}
              title={localizeString('В корзину','Add to cart',locale)}
              // title={`${product.sizes[selectedSize]['price']} ${localizeString('руб.','rub.',locale)}`}
            />
      }
    </div>
  </div>
}

const SauceItem = ({ product, cartItems, locale, cartId }) => {
  const [addToCart] = useMutation(ADD_TO_CART, {
    update: updateCacheAddToCart
  })
  const [updateInCart] = useMutation(UPDATE_IN_CART, { 
    update: updateCacheUpdateInCart
  })
  const [removeFromCart] = useMutation(REMOVE_FROM_CART, { 
    update: updateCacheRemoveFromCart
  })
  const [selectedSize, selectSize] = useState(0)
  const foundItem = cartItems.find(item => (item.productId === product.id && item.selectedSize === selectedSize)),
        foundIndex = cartItems.findIndex(item => (item.productId === product.id && item.selectedSize === selectedSize))
  
  const handleClick = (e) => {
    e.preventDefault()
    addToCart(
      { 
        variables: {
          productId: product.id,
          selectedSize: selectedSize,
          price: product.sizes[selectedSize]['price'],
          productType: 'sauce',
          quantity: 1
        }
      }
    )
  }

  const handlerIncrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'plus'
        }
      }
    )
  }

  const handlerDecrease = (e) => {
    e.preventDefault()
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex,
          mode: 'minus'
        }
      }
    )
  }

  const handlerRemove = (e) => {
    e.preventDefault()
    removeFromCart(
      { 
        variables: {
          id: cartId,
          rowId: foundIndex
        }
      }
    )
  }

  const imageURL = product.sizes[selectedSize]['image']['url'] + '?w=' + 256

  return <div className="menu-item">
    <div className="product-image">
      <img src={imageURL} alt="" />
    </div>
    <div className="product-description">
      <h3 className="product-title">{product.title}</h3>
      <div className="product-sizes">
        <ul>
          {
            product.sizes.map((size,index)=> {
              return <li 
                       key={index}
                       className={selectedSize === index ? 'active' : ''}
                       onClick={()=>selectSize(index)}
                     >{formatCapacity(size.capacity, locale)}</li>
            })
          }
        </ul>
      </div>
    </div>
    <div className="product-management">
      <div className="product-price">
        {`${product.sizes[selectedSize]['price']*(foundItem?.quantity || 1)}`}
        <span>{localizeString('руб.','rub.',locale)}</span>
      </div>
      {
        foundItem
          ? <div className="product-management-controls">
              {
                foundItem.quantity > 1
                  ? <button
                      className="count-control-button button-minus"
                      onClick={handlerDecrease}>
                      <img src={`/static/icons/button-minus.svg`} alt="" />
                    </button>
                  : <button
                      className="count-control-button button-remove"
                      onClick={handlerRemove}>
                      <img src={`/static/icons/button-minus.svg`} alt="" />
                    </button>
              }
              <span className="count-control-value">{foundItem.quantity}</span>
              <button
                className="count-control-button button-plus"
                onClick={handlerIncrease}>
                <img src={`/static/icons/button-plus.svg`} alt="" />
              </button>
            </div>
          : <Button
              customStyle="branded"
              handle={handleClick}
              title={localizeString('В корзину','Add to cart',locale)}
            />
      }
    </div>
  </div>
}

function Products({ device, navbar, products }) {
  const { locale } = device
  const menu = navbar.filter(navbarItem => navbarItem.alias !== 'delivery-map')
  const { data } = useQuery(GET_CART)

  return (
    <div className="menu" id="products">
      {
        menu.map((menuItem) =>
          <React.Fragment key={menuItem.id}>
            <h2 className="menu-category-title" id={menuItem.elementId}>{menuItem.name}</h2>
            <div className="menu-section">
              {
                (menuItem.alias === 'pizza' || menuItem.alias === 'sushi') &&
                products.filter(product => product.type === menuItem.alias).map((item) =>
                  <PizzaItem
                    key={item.id}
                    locale={locale}
                    product={item}
                    cartItems={data?.cart?.items || []}
                    cartId={data?.cart?.id}
                  />
                )
              }
              {
                menuItem.alias === 'snack' &&
                products.filter(p => p.type === menuItem.alias).map((item) => {
                  return (
                    <SnackItem
                      key={item.id}
                      locale={locale}
                      product={item}
                      cartItems={data?.cart?.items || []}
                      cartId={data?.cart?.id}
                    />
                  )
                })
              }
              {
                menuItem.alias === 'salad' &&
                products.filter(p => p.type === menuItem.alias).map((item) => {
                  return (
                    <SaladItem
                      key={item.id}
                      locale={locale}
                      product={item}
                      cartItems={data?.cart?.items || []}
                      cartId={data?.cart?.id}
                    />
                  )
                })
              }
              {
                menuItem.alias === 'drink' &&
                products.filter(product => product.type === menuItem.alias).map((item) =>
                  <DrinkItem
                    key={item.id}
                    locale={locale}
                    product={item}
                    cartItems={data?.cart?.items || []}
                    cartId={data?.cart?.id}
                  />
                )
              }
              {
                menuItem.alias === 'sauce' &&
                products.filter(product => product.type === menuItem.alias).map((item) =>
                  <SauceItem
                    key={item.id}
                    locale={locale}
                    product={item}
                    cartItems={data?.cart?.items || []}
                    cartId={data?.cart?.id}
                  />
                )
              }
            </div>
          </React.Fragment>
        )
      }
    </div>
  )
}

export default Products