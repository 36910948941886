import React from 'react'
import { Link } from 'react-router-dom'
import DropdownModern from './DropdownModern'
import RuLangIcon from '../assets/icons/flags/ru.svg'
import EnLangIcon from '../assets/icons/flags/en.svg'
import { INITIAL, UPDATE_DEVICE_LOCALE } from '../queries'
import { useMutation } from '@apollo/client'

function HeaderCompact({ device }) {
  const { locale } = device
  const avaliableLanguages = [
          <><img src={RuLangIcon} className="lang-icon" alt="" /><span>Русский</span></>,
          <><img src={EnLangIcon} className="lang-icon" alt="" /><span>English</span></>
        ]

  const [updateLocaleDevice] = useMutation(UPDATE_DEVICE_LOCALE)

  const changeLanguage = ({ index }) => {
    const langList = ["ru","en"]
    updateLocaleDevice(
      { 
        variables: {
          id: device.id,
          locale: langList[index]
        },
        refetchQueries: [
          { query: INITIAL }
        ]
      }
    )
  }

  return (
    <div className="header-compact">
      <div className="header-compact-container">
        <div className="header-compact-logo">
          <Link to="/">
            <img src={`/static/images/logo-FF-DIN-Round-bold-branded.svg`} alt="" />
          </Link>
        </div>
        <div className="header-compact-content">
          <div className="header-compact-select-lang">
            <DropdownModern
              items={avaliableLanguages}
              handle={changeLanguage}
              customClassDropdown='select-lang-dropdown-body'
              defaultSelected={ locale === "ru" ? 0 : 1 || 0 }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderCompact
