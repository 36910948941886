import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from './Button'
import { useMutation } from '@apollo/client'
import { UPDATE_DEVICE_GDPR, INITIAL } from '../queries'

// GDPRPanel
export default function CookiePolicyContainer({ device }) {
  const { locale, gdprStatus } = device
  const { city: cityAlias } = useParams()
  const [visible, setVisible] = useState(gdprStatus)

  const updateCacheDeviceGDPR = (cache, { data: { updateDevice } }) => {
    const { device: prevDevice } = cache.readQuery({ 
      query: INITIAL, variables: { city: cityAlias } 
    })

    const newDevice = {
      ...prevDevice,
      gdprStatus: updateDevice.gdprStatus
    }

    cache.writeQuery({
      query: INITIAL, variables: { city: cityAlias } ,
      data: {
        device: newDevice
      }
    })
  }

  const [updateDeviceGDPR] = useMutation(UPDATE_DEVICE_GDPR, { 
    update: updateCacheDeviceGDPR,
    onCompleted: () => setVisible(visible ? false : true)
  })

  const handleButton = (e) => {
    e.preventDefault()
    updateDeviceGDPR(
      { 
        variables: {
          id: device.id,
          gdprStatus: false
        }
      }
    )
  }

  return (
    <div className={`cookie-policy-container ${!visible && "hide"}`}>
      <div className="cookie-policy-container__first">
        <p>{cookiesLocale['lineFirst'][locale]}</p>
        <p>{`${cookiesLocale['lineSecond'][locale]}`}
        <a href={`/privacy-policy`} target="_blank" rel='noopener noreferrer'>{cookiesLocale['link'][locale]}</a></p>
      </div>
      <div className="cookie-policy-container__last">
        <Button handle={handleButton}>{cookiesLocale['button'][locale]}</Button>
      </div>
    </div>
  )
}

const cookiesLocale = {
  lineFirst: {
    ru: "Мы используем cookies для быстрой и удобной работы сайта.",
    en: "We use cookies to enable an improved browsing experience"
  },
  lineSecond: {
    ru: "Продолжая пользоваться сайтом, вы принимаете ",
    en: "By continuing to access the website, you agree with "
  },
  link: {
    ru: "Политику обработки персональных данных",
    en: "Privacy Policy"
  },
  button: {
    ru: "Принять",
    en: "Accept"
  }
}

  // Text for English version
  // We use cookies to enable an improved browsing experience, and to share information with our marketing partners. By continuing to access the website, you agree that we will store data in a cookie as outlined in our Privacy Policy.