import React from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  HELMET_TITLE,
  DEFAULT_HELMET_TITLE,
  DEFAULT_HELMET_TITLE_SHORT,
  META_DESCRIPTION,
  DEFAULT_META_DESCRIPTION,
  META_KEYWORDS,
  DEFAULT_META_KEYWORDS,
  declensionCities,
} from '../shared/constants'

function formatTitle(title = '', cityNames = [], phone = '') {
  const titleWithCity = HELMET_TITLE
    .replace('{{city}}', cityNames[1])
    .replace('{{phone}}', phone)
  if (title !== '') return `${title} - ${titleWithCity}` 
  return titleWithCity
}

export default function HelmetWrapper({ title, phone = '' }) {
  const { city } = useParams()

  if (city === undefined) return (
    <Helmet>
      <title>{`${title} - ${DEFAULT_HELMET_TITLE}`}</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION} />
      <meta name="keywords" content={DEFAULT_META_KEYWORDS}></meta>
      <meta name="og:title" content={DEFAULT_HELMET_TITLE_SHORT} />
      <meta name="og:description" content="Доставка горячей пиццы в вашем городе. Большая и дешевая, бесплатная доставка" />
      <meta name="og:image" content="https://abb11bdb-5b7b-4561-8311-fadde7607aa1.selcdn.net/uploads/fridaypizza-shared-image.jpg?h=480" />
    </Helmet>
  )

  const cityNames = declensionCities[city] || null

  return (
    <Helmet>
      <title>{formatTitle(title, cityNames, phone)}</title>
      <meta name="description" content={META_DESCRIPTION.replace('{{city}}', cityNames[1])} />
      <meta name="keywords" content={META_KEYWORDS.replace('{{city}}', cityNames[0].toLowerCase())}></meta>
      <meta name="og:title" content={`Пятница Пицца - Доставка пиццы в ${cityNames[1] || ''} домой и в офис`} />
      <meta name="og:description" content="Доставка горячей пиццы в вашем городе. Большая и дешевая, бесплатная доставка." />
      <meta name="og:image" content="https://abb11bdb-5b7b-4561-8311-fadde7607aa1.selcdn.net/uploads/fridaypizza-shared-image.jpg?h=480" />
      <meta name="og:phone_number" content={phone} />
    </Helmet>
  )
}
