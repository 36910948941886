import React from 'react'
import { useParams } from 'react-router-dom'

export default function HomeDesktopSEO() {
  const { city } = useParams()
  if (city === 'saransk') return (
    <div className="seo-container">
      <h2>Доставка пиццы в Саранске</h2>
      <p>Сделайте заказ в пиццерии "Пятница Пицца" онлайн или по телефону и наслаждайтесь изысканной начинкой из качественных ингредиентов, большая часть которых произведена на территории России.</p>
      <p>Попробуйте пиццу из пиццерии «Пятница Пицца» и найдите свой любимый вкус, будь то самая популярная пицца в мире "Пепперони", пицца с сочетанием вкусов ананаса и курицы –  "Гавайская" , а также наши пиццы по оригинальным рецептам "Вечеринка", "Шантаж" и "Загул". Экспериментируйте и узнайте, что по душе Вам, а также вашим родным и друзьям.</p>
      <p>Курьер доставит пиццу в Саранске по указанному адресу к Вам в офис или домой быстро и в короткий срок.</p>
      <p>Пиццерия "Пятница Пицца" – отличный выбор для заказа пиццы с доставкой в удобное для Вас время.</p>
    </div>
  )
  if (city === 'penza') return (
    <div className="seo-container">
      <h2>Доставка пиццы в Пензе</h2>
      <p>Сделайте заказ в пиццерии "Пятница Пицца" онлайн или по телефону и наслаждайтесь изысканной начинкой из качественных ингредиентов, большая часть которых произведена на территории России.</p>
      <p>Попробуйте пиццу из пиццерии «Пятница Пицца» и найдите свой любимый вкус, будь то самая популярная пицца в мире "Пепперони", пицца с сочетанием вкусов ананаса и курицы –  "Гавайская" , а также наши пиццы по оригинальным рецептам "Вечеринка", "Шантаж" и "Загул". Экспериментируйте и узнайте, что по душе Вам, а также вашим родным и друзьям.</p>
      <p>Курьер доставит пиццу в Пензе по указанному адресу к Вам в офис или домой быстро и в короткий срок.</p>
      <p>Пиццерия "Пятница Пицца" – отличный выбор для заказа пиццы с доставкой в удобное для Вас время.</p>
    </div>
  )
  if (city === 'ryazan') return (
    <div className="seo-container">
      <h2>Доставка пиццы в Рязани</h2>
      <p>Сделайте заказ в пиццерии "Пятница Пицца" онлайн или по телефону и наслаждайтесь изысканной начинкой из качественных ингредиентов, большая часть которых произведена на территории России.</p>
      <p>Попробуйте пиццу из пиццерии «Пятница Пицца» и найдите свой любимый вкус, будь то самая популярная пицца в мире "Пепперони", пицца с сочетанием вкусов ананаса и курицы –  "Гавайская" , а также наши пиццы по оригинальным рецептам "Вечеринка", "Шантаж" и "Загул". Экспериментируйте и узнайте, что по душе Вам, а также вашим родным и друзьям.</p>
      <p>Курьер доставит пиццу в Рязани по указанному адресу к Вам в офис или домой быстро и в короткий срок.</p>
      <p>Пиццерия "Пятница Пицца" – отличный выбор для заказа пиццы с доставкой в удобное для Вас время.</p>
    </div>
  )
  if (city === 'tambov') return (
    <div className="seo-container">
      <h2>Доставка пиццы в Тамбове</h2>
      <p>Сделайте заказ в пиццерии "Пятница Пицца" онлайн или по телефону и наслаждайтесь изысканной начинкой из качественных ингредиентов, большая часть которых произведена на территории России.</p>
      <p>Попробуйте пиццу из пиццерии «Пятница Пицца» и найдите свой любимый вкус, будь то самая популярная пицца в мире "Пепперони", пицца с сочетанием вкусов ананаса и курицы –  "Гавайская" , а также наши пиццы по оригинальным рецептам "Вечеринка", "Шантаж" и "Загул". Экспериментируйте и узнайте, что по душе Вам, а также вашим родным и друзьям.</p>
      <p>Курьер доставит пиццу в Тамбове по указанному адресу к Вам в офис или домой быстро и в короткий срок.</p>
      <p>Пиццерия "Пятница Пицца" – отличный выбор для заказа пиццы с доставкой в удобное для Вас время.</p>
    </div>
  )
  if (city === 'nizhny-novgorod') return (
    <div className="seo-container">
      <h2>Доставка пиццы в Нижнем Новгороде</h2>
      <p>Сделайте заказ в пиццерии "Пятница Пицца" онлайн или по телефону и наслаждайтесь изысканной начинкой из качественных ингредиентов, большая часть которых произведена на территории России.</p>
      <p>Попробуйте пиццу из пиццерии «Пятница Пицца» и найдите свой любимый вкус, будь то самая популярная пицца в мире "Пепперони", пицца с сочетанием вкусов ананаса и курицы –  "Гавайская" , а также наши пиццы по оригинальным рецептам "Вечеринка", "Шантаж" и "Загул". Экспериментируйте и узнайте, что по душе Вам, а также вашим родным и друзьям.</p>
      <p>Курьер доставит пиццу в Нижнем Новгороде по указанному адресу к Вам в офис или домой быстро и в короткий срок.</p>
      <p>Пиццерия "Пятница Пицца" – отличный выбор для заказа пиццы с доставкой в удобное для Вас время.</p>
    </div>
  )
  if (city === 'lipetsk') return (
    <div className="seo-container">
      <h2>Доставка пиццы в Липецке</h2>
      <p>Сделайте заказ в пиццерии "Пятница Пицца" онлайн или по телефону и наслаждайтесь изысканной начинкой из качественных ингредиентов, большая часть которых произведена на территории России.</p>
      <p>Попробуйте пиццу из пиццерии «Пятница Пицца» и найдите свой любимый вкус, будь то самая популярная пицца в мире "Пепперони", пицца с сочетанием вкусов ананаса и курицы –  "Гавайская" , а также наши пиццы по оригинальным рецептам "Вечеринка", "Шантаж" и "Загул". Экспериментируйте и узнайте, что по душе Вам, а также вашим родным и друзьям.</p>
      <p>Курьер доставит пиццу в Липецке по указанному адресу к Вам в офис или домой быстро и в короткий срок.</p>
      <p>Пиццерия "Пятница Пицца" – отличный выбор для заказа пиццы с доставкой в удобное для Вас время.</p>
    </div>
  )
  return null
}
// {/* <div className="seo-container">
// <h2>Доставка пиццы в Рязани</h2>
// <p>Хотите. быстро подкрепиться в офисе или устроить лучший романтический вечер со второй половинкой? Для этого просто оформите заказ пиццы из ресторана «Пати Пицца» в Рязани.</p>
// <p>Мы найдем путь к сердцу каждого гурмана. Любителям необычных сочетаний придется по вкусу «Гавайская» с ананасом и ветчиной, а «Мясное удовольствие» понравится людям, обожающим сырокопченые колбаски, ароматный бекон и свинину.</p>
// <p>Творческим личностям, открытым для всего нового, стоит попробовать вкусную пиццу по собственному рецепту. При этом вам не придется идти на кухню. Достаточно воспользоваться онлайн-конструктором на сайте. Если вы еще не готовы к смелым экспериментам, измените ингредиенты в блюдах из меню.</p>
// <h3>Несколько слов о составе нашей пиццы…</h3>
// <p>Главным секретом вкуса наших блюд являются качественные ингредиенты. Каждый из них соответствует требованиям Золотого стандарта. У нас вы сможете заказать пиццу, каждый компонент которой прошел тщательную многоступенчатую проверку. Она проводится на региональном, федеральном и международном уровне.</p>
// <p>Большая часть используемых нами компонентов выпущено в Российской Федерации. Поэтому наши постоянные клиенты вкусно завтракают, обедают и ужинают, а также поддерживают экономику страны.</p>
// <h3>Почему мы?</h3>
// <p><strong>Свежее тесто.</strong> Оно прибывает к нам в ресторан уже готовым и никогда не замораживается. Мы раскатываем его ровно за минуту перед поступлением в печь.</p>
// <p><strong>Разнообразное меню.</strong> В нашем ресторане представлен широкий ассортимент безалкогольных напитков, нежных десертов и аппетитных салатов. Попробуйте наши сладкие узелки, «Греческий», квас или морс.</p>
// <p><strong>Без разогрева.</strong> Сразу после выпекания пицца помещается в термосумку. Поэтому вам не придется подогревать ее перед подачей на стол.</p>
// <p><strong>Быстрая доставка пиццы.</strong> Мы привезем ваш заказ в любой район Минска. Бесплатная служба доставки пиццы работает практически круглосуточно.</p>
// <p><strong>Возможность выбрать любой размер.</strong> Мы предлагаем вам блюдо с основой «Традиция» или на тонком тесте. Например, для очень большой компании стоит заказать пиццу 40 см в диаметре, а для ланча достаточно и 23 см.</p>
// <p><strong>Вкусный сюрприз.</strong> Участвуйте в программе «Папа Бонус». За каждый заказ пиццы на дом или в офис на ваш счет будут начисляться баллы. Их вы сможете потратить на подарки из нашего ресторана. Если вы еще не знаете, где купить пиццу, присоединитесь к числу клиентов ресторана «Папа Джонс». Наша служба доставки работает днем и ночью. Мы будем рады помочь утолить ваш голод практически в любое время суток.</p>
// <p>Чтобы оформить заказ и уточнить цену любимых блюд, звоните по номерам пиццерии, указанным в разделе «Телефоны». Приятного аппетита!</p>
// </div> */}
