import React from 'react'
import { localizeString } from '../shared/functions'
// import useCartDesctiption from '../hooks/useCartDesctiption'
import useDeliveryCost from '../hooks/useDeliveryCost'

export default function CartTotal({
  locale,
  cartId,
  cartItems = [],
  isDelivery = true,
  isPickup = false,
  discount = 0,
  grandTotal = 0,
  customerAddress,
  currentCity,
  handleRestaurantIdFromDeliveryCost
}) {
  const [, deliveryCost, getDeliveryCost] = useDeliveryCost()

  React.useEffect(() => {
    if (deliveryCost) {
      if (deliveryCost.zone) {
        console.log('[CartTotal] deliveryCost.zone.restaurantId:', deliveryCost.zone.restaurantId)
        handleRestaurantIdFromDeliveryCost &&
        handleRestaurantIdFromDeliveryCost(deliveryCost.zone.restaurantId)
      }
    }
  }, [deliveryCost])

  React.useEffect(() => {
    const { coordinates } = customerAddress
    if (coordinates) {
      if (coordinates.latitude && coordinates.longitude) {
        const { latitude, longitude } = coordinates
        // Get delivery cost
        getDeliveryCost({
          latitude,
          longitude,
        }, currentCity.id)
        // Get cart total with delivery cost
        // getCartDescription({
        //   coordinates,
        // })
      }
    }
  }, [cartId, customerAddress.coordinates, cartItems])

  return (
    <div className='cart-total'>
      {
        isPickup &&
        <Line
          title={localizeString('Скидка', 'Discount', locale)}
          value={discount.amount || 0}
          locale={locale}
        />
      }
      {
        isDelivery &&
        <Line
          title={
            currentCity.id === '64e5f04422a1c944d1f34ffa' &&
            deliveryCost?.title == 'Вне зоны доставки'
              ? 'Стоимость доставки уточняйте у оператора'
              : deliveryCost?.title || ''
          }
          value={deliveryCost?.cost?.amount || -1}
          locale={locale}
        />
      }
      <Line
				title={localizeString('К оплате', 'Total', locale)}
        value={grandTotal.amount + (deliveryCost?.cost?.amount || 0)}
				locale={locale}
			/>
    </div>
  )
}

const Line = ({ title, value, locale }) => {
  return (
    <div className='cart-total-line'>
      <div>
        <h2>
          {
            title !== ''
              ? title
              : ' '
          }
        </h2>
      </div>
      <div
        style={{
          flexDirection: 'row',
          alignItems: 'baseline',
          justifyContent: 'flex-end',
        }}
      >
        {
          value >= 0 &&
          <>
            <span
              className='cart-total-line-span-value'
            >
              {value}
            </span>
            <span
              className='cart-total-line-span-currency'
            >
              {localizeString('руб.', 'rub.', locale)}
            </span>
          </>
        }
      </div>
    </div>
  )
}