import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Navbar from './Navbar'
import CartButton from './CartButton'
import DropdownModern from './DropdownModern'
import MapMarkerIcon from '../assets/icons/navigation/geo.svg'
import ClockIcon from '../assets/icons/navigation/clock.svg'
import PhoneIcon from '../assets/icons/navigation/phone.svg'
import RuLangIcon from '../assets/icons/flags/ru.svg'
import EnLangIcon from '../assets/icons/flags/en.svg'
import { GET_CART, CLEAR_CART_AND_CHANGE_CITY, INITIAL, UPDATE_DEVICE_LOCALE } from '../queries'
import { useMutation } from '@apollo/client'
import MobileMenu from './MobileMenu'

function Header({ device, restaurant, navbar, cities, isMobile }) {
  const { city: cityAlias } = useParams()
  const defaultSelectedCity = cities.findIndex((city) => city.alias === cityAlias) || 0
  const { locale } = device
  const [isVisibleCategories, updateVisibleCategories] = useState(false)
  const [productsPositions, updateProductsPositions] = useState([])
  const [glowingCategory, setGlowingCategory] = useState(0)
  const [mobileMenuActive, setMobileMenuActive] = useState(false)

  // Redirect to selected city
  const redirectToCity = ({ updateDevice: { cityId }}) => {
    // Search city by cityId and get alias of city
    const { alias } = cities.find((city) => city.id === cityId)

    // Redirect to the city of device
    window.location = `/${alias}`
  }

  // Update device city and clear customer cart
  const [clearCustomerCartAndChangeCity] = useMutation(CLEAR_CART_AND_CHANGE_CITY, {
    onCompleted: redirectToCity
  })

  const handleClick = ({ index }) => {
    clearCustomerCartAndChangeCity(
      {
        variables: {
          deviceId: device.id,
          cityId: cities[index]['id']
        }
      }
    )
  }

  // change locale hooks
  // const updateCacheLocaleDevice = (cache, { data: { updateDevice } }) => {
  //   const { device: prevDevice } = cache.readQuery({ 
  //     query: INITIAL
  //   })
  //   console.log('updateDevice:', updateDevice)
  //   const newDevice = {
  //     ...prevDevice,
  //     locale: updateDevice.locale
  //   }

  //   cache.writeQuery({
  //     query: INITIAL,
  //     data: {
  //       device: newDevice
  //     }
  //   })
  // }
  const [updateLocaleDevice] = useMutation(UPDATE_DEVICE_LOCALE, { 
    // update: updateCacheLocaleDevice,
    // onCompleted: loadedHandle
  })

  // const isVisibleCategories = () => {
  //   // return el.getBoundingClientRect().bottom-100 <= window.innerHeight
  //   // return el.getBoundingClientRect().top+60 <= window.scrollY
  //   // console.log(el)
  //   return productsElementY >= window.scrollY
  // }

  function handleScroll() {
    // show navbar when scroll down of the banners
    const productsElement = document.getElementById('products') || null
    if (productsElement !== null) {
      // spy for scroll and show the carousel of categories
      // default 60, but now testing
      (productsElement.getBoundingClientRect().top < 75 && window.scrollY !== 0)
        ? updateVisibleCategories(true)
        : updateVisibleCategories(false)
    }

    // spy for scroll for glow current category
    if (productsPositions.length > 0) {
      productsPositions.forEach((pos,index) => {
        let nextPos = productsPositions[index+1] ? productsPositions[index+1] - (isMobile ? 60 : 126) : 9999
        if (window.scrollY > pos - (isMobile ? 60 : 126) && window.scrollY < nextPos) {
          setGlowingCategory(index)
          // console.log('setGlowingCategory:', index)
        }
      })
    }
  }

  useEffect(() => {
    function searchProductsPosition() {
      for (let navbarItem of navbar){
        const elem = document.getElementById(navbarItem.elementId) || null
        if (elem !== null) {
          if (window.scrollY > 0) {
            updateProductsPositions(productsPositions.push(elem.getBoundingClientRect().top + window.scrollY))
          } else {
            updateProductsPositions(productsPositions.push(elem.getBoundingClientRect().top))
          }
        }
      }
    }
    // Slick slider need to initialize some time
    // Use timer for get real offset from top
    const myTimer = setTimeout(() => searchProductsPosition(),100)
    return () => {
      clearInterval(myTimer)
    }
  }, [])

  useEffect( () => {
    // subscribe event
    window.addEventListener("scroll", handleScroll)
    return () => {
      // unsubscribe event
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    const navbarItems = document.getElementsByClassName('category-item-name') || []
    let mainContainer = document.getElementsByClassName('carousel-categories-container')[0],
        targetLi = navbarItems[glowingCategory],
        widthElements = []

    for (const htmlElem of navbarItems) {
      widthElements.push(htmlElem.getBoundingClientRect().width)
    }

    if (targetLi) {
      let offsetFromLeft = targetLi.getBoundingClientRect().left - 20
      if (glowingCategory > 0) {
        console.log('widthElements',widthElements)
        // const shit = widthElements
        offsetFromLeft = widthElements
          .slice(0,glowingCategory)
          // .map((el) => el.getBoundingClientRect().width)
          .reduce((a,b) => a+b)
        // console.log('shit',shit)
      }

      mainContainer.scrollTo({
        // left: targetLi.getBoundingClientRect().left - 20,
        left: offsetFromLeft,
        top: 0, 
        behavior: 'smooth'
      })
    }

  }, [glowingCategory])

  const scrollToRef = (ref,pageOffset) => window.scrollTo({
    left: 0,
    top: ref.getBoundingClientRect().top + pageOffset - 59, 
    behavior: 'smooth'
  })

  const goToCategory = (event,elementNameID) => {
    event.preventDefault()
    const el = document.getElementById(elementNameID)
    scrollToRef(el,window.scrollY)
    handleScroll()
  }

  const avaliableLanguages = [
          <><img src={RuLangIcon} className="lang-icon" alt="" /><span>Русский</span></>,
          <><img src={EnLangIcon} className="lang-icon" alt="" /><span>English</span></>
        ]

  const changeLanguage = ({ index }) => {
    const langList = ["ru","en"]
    updateLocaleDevice(
      { 
        variables: {
          id: device.id,
          locale: langList[index]
        },
        refetchQueries: [
          { query: INITIAL, variables: { city: cityAlias } },
          { query: GET_CART }
        ]
      }
    )
  }

  const mobileMenuHandle = () => {
    setMobileMenuActive(prevState => !prevState)
  }

  // if (isVisibleCategories) {
  //   return (
  //     <div className="header header-with-categories header-drop-shadow">
  //       <div className="carousel-categories-wrapper">
  //         <div className="carousel-categories-container">
  //           <ul>
  //             {
  //               navbar.map((category, index) => {
  //                 return (
  //                   <li key={category.id} className={index === glowingCategory ? "active" : ''} onClick={(e)=> {goToCategory(e,category.elementId)}}>
  //                     <div className="category-item-name">
  //                       {category.name}
  //                     </div>
  //                   </li>
  //                 )
  //               })
  //             }
  //           </ul>
  //           {
  //             !isMobile ? <Link to={`/${cityAlias}/order`}><CartButton /></Link> : null
  //           }
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className={`header ${isVisibleCategories ? 'header-drop-shadow' : ''}`}>
      {
        isMobile &&
        <Navbar
          buttonLeft=''
          buttonRight={mobileMenuActive ? 'close' : 'burger'}
          rightHandle={mobileMenuHandle}
          customClass={isVisibleCategories ? 'low-z-index' : ''}
        />
      }
      {
        isMobile &&
        <MobileMenu
          device={device}
          restaurant={restaurant}
          cities={cities}
          avaliableLanguages={avaliableLanguages}
          handleLanguage={changeLanguage}
          handleCity={handleClick}
          defaultSelectedCity={defaultSelectedCity}
          isVisible={mobileMenuActive}
        />
      }
      <div className="header-container">
        <div className="header-logo">
          <Link to={`/${cityAlias}`}>
            <img src={`/static/images/logo-FF-DIN-Round-bold-branded.svg`} alt="" />
          </Link>
        </div>
        {
          !isMobile &&
          <div className="header-desktop">
            <DropdownModern 
              items={cities.map(city => <h2>{city.name}</h2>)}
              defaultSelected={defaultSelectedCity}
              buttonIcon={<img src={MapMarkerIcon} className="icon" alt="" />}
              customClassButton="button-select-city"
              handle={handleClick}
            />
            {/* <div className="header-desktop-item">
              <img src={MapMarkerIcon} className="icon" alt="" />
              <h2>{cities[0]['name']}</h2>
            </div> */}
            <div className="header-desktop-item">
              <img src={ClockIcon} className="icon ml-1" alt="" />
              {/* <ClockIcon className="icon ml-1" /> */}
              <h2>{restaurant.workingTime.main === '24' ? '24 часа' : restaurant.workingTime.main}</h2>
            </div>
            <div className="header-desktop-item">
              <img src={PhoneIcon} className="icon" alt="" />
              {/* <PhoneIcon className="icon" /> */}
              <h2><a href={`tel:${restaurant.phone}`} rel="noopener noreferrer">{restaurant.phone}</a></h2>
            </div>
            <div className="header-login">
              {/* <Button>Войти</Button> */}
              <DropdownModern 
                items={avaliableLanguages}
                handle={changeLanguage}
                customClass='mr1'
                defaultSelected={ locale === "ru" ? 0 : 1 || 0 }
              />
              <Link to={`/${cityAlias}/order`}><CartButton customStyle="branded ml-1" /></Link>
            </div>
          </div>
        }
      </div>
      <div className={`carousel-categories-wrapper ${isVisibleCategories ? 'active' : ''}`}>
        <div className="carousel-categories-container">
          <ul>
            {
              navbar.map((category, index) => {
                let className = ''
                if (index === glowingCategory) {
                  className = 'active'
                }
                return (
                  <li key={category.id} className={className} onClick={(e)=> {goToCategory(e,category.elementId)}}>
                    <div className="category-item-name">
                      {category.name}
                    </div>
                  </li>
                )
              })
            }
            <li style={{ width: 250+'px' }}>&nbsp;</li>
          </ul>
          {
            !isMobile ? <Link to={`/${cityAlias}/order`}><CartButton /></Link> : null
          }
        </div>
      </div>
    </div>
  )
}

export default Header
