import { InMemoryCache, makeVar } from '@apollo/client'
import Cookies from 'universal-cookie'
// import { GET_LOCALE } from '../queries'

// export const cartItemsVar = makeVar([])
const cookies = new Cookies()

export const localeVar = makeVar(cookies.get('locale') || 'ru')

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__)

// cache.writeQuery({
//   // query: IS_LOGGED_IN,
//   // data: {
//   //   isLoggedIn: !!localStorage.getItem("token") || 'shit',
//   // },
//   query: GET_LOCALE,
//   data: {
//     locale: localeVar()
//   },
//   // typePolicies: {
//   //   Query: {
//   //     fields: {
//   //       locale: {
//   //         read() {
//   //           return localeVar()
//   //         }
//   //       }
//   //     }
//   //   }
//   // }
// })

export default cache