import React, { useState } from 'react'

export default function InputRadio({
  name = '',
  label = '',
  width = null,
  checked = false,
  rowId = null,
  requiredShow = false,
  customStyle,
  handle
}) {
  const styles = {}

  if (width) {
    styles.width = width
  }

  if (checked) {
    styles.cursor = 'default'
  }

  const radioHandler = () => {
    handle && 
    handle({
      name,
      rowId,
      value: !checked
    })
  }

  return (
    <label 
      className={
        customStyle 
        ? `input-radio ${customStyle} ${requiredShow ? 'required' : ''}` 
        : `input-radio ${requiredShow ? 'required' : ''}`
      }
      style={styles}
    >
      <span className="label-name">{label}</span>
      <input
        type="radio" 
        className={checked ? "active" : ""}
        name={name}
        // checked={radioState}
        checked={checked}
        onChange={radioHandler} 
      />
      <span className={checked ? "checkmark active" : "checkmark"}></span>
    </label>
  )
}
