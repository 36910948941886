import React from 'react'
import { Link, useParams } from 'react-router-dom'
import CookiePolicyContainer from './CookiePolicyContainer'
import VkIcon from '../assets/icons/social/vk-white.svg'
import InstagramIcon from '../assets/icons/social/instagram-white.svg'
// import { useQuery } from '@apollo/client'
// import { GET_LOCALE } from '../queries'

export default function Footer({ social, device }) {
  const { locale } = device
  const { city: cityAlias } = useParams()
  let year = new Date()

  return (
    <div className="footer">
      <div className="first-line">
        <div className="footer-menu-wrapper">
          <ul className="footer-menu">
            <li>{footerLocale['companyName'][locale]}</li>
            <li><Link to={`/${cityAlias}/about-us`}>{footerLocale['aboutUs'][locale]}</Link></li>
            <li><Link to={`/${cityAlias}/contacts`}>{footerLocale['contacts'][locale]}</Link></li>
          </ul>
          <ul className="footer-menu">
            <li>{footerLocale['legal'][locale]}</li>
            <li><Link to={`/${cityAlias}/user-agreement`}>{footerLocale['userAgreement'][locale]}</Link></li>
            <li><a href={`/privacy-policy`} target="_blank" rel='noopener noreferrer'>{footerLocale['pdpp'][locale]}</a></li>
          </ul>
        </div>
        <ul className="footer-social">
          {
            social ?
            social.map( (item, index) => {
              return <li key={index}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={footerSocial[item.icon]} className="icon" alt="" /></a></li>
            }) : null
          }
        </ul>
      </div>
      <div className="second-line">
        <p>{`${footerLocale['companyName'][locale]} © ${year.getFullYear()} ${footerLocale['copyright'][locale]}`}</p>
        <p>Made with <img className="icon" src="/static/icons/heart.svg" alt="" /> by Foterio</p>
      </div>
      <CookiePolicyContainer 
        locale={locale}
        device={device}
      />
    </div>
  )
}

// Изображения продуктов могут отличаться от продуктов в заказе.
// Цены в меню указаны без учета скидок

const footerSocial = {
  vk: VkIcon,
  instagram: InstagramIcon
}

const footerLocale = {
  companyName: {
    ru: "Пятница Пицца",
    en: "Friday Pizza"
  },
  aboutUs: {
    ru: "О компании",
    en: "About Us"
  },
  contacts: {
    ru: "Контакты",
    en: "Contacts"
  },
  legal: {
    ru: "Правовая информация",
    en: "Legal"
  },
  userAgreement: {
    ru: "Пользовательское соглашение",
    en: "User Agreement"
  },
  pdpp: {
    ru: "Политика обработки персональных данных",
    en: "Privacy policy"
  },
  copyright: {
    ru: "Все права защищены. Возраст 6+",
    en: "All right reserved. 6+"
  },
}

// <img className="icon" src="/static/icons/social/vk.svg" alt="" />
// <img className="icon" src="/static/icons/social/instagram.svg" alt="" />