import React from 'react'
import Header from '../components/Header'
import Products from '../components/Products'
import HomeDesktopSEO from '../components/HomeDesktopSEO'
import Footer from '../components/Footer'
import HelmetWrapper from '../components/HelmetWrapper'
import ImgSlider from '../components/ImgSlider'
import DeliveryZone from '../components/DeliveryZone'

// Временное решение
const isMobile = false

export default function HomePage(props, { data }) {
  const { device, cities, banners, products, navbar, restaurant, map } = props.data

  return (
    <>
      <HelmetWrapper
        title=''
        phone={restaurant.phone} 
      />
      <Header 
        cities={cities}
        navbar={navbar}
        restaurant={restaurant}
        device={device}
        isMobile={isMobile}
      />
      <ImgSlider
        isMobile={isMobile}
        banners={banners}
      />
      <Products 
        products={products} 
        device={device}
        navbar={navbar} 
      />
      <DeliveryZone
        device={device}
        isMobile={isMobile}
        restaurant={restaurant}
        data={map}
        customTitleClass='title-in-home-page'
      />
      <HomeDesktopSEO />
      <Footer
        device={device}
        social={restaurant.social} 
      />
    </>
  )
}

// const { loading, error, data } = useQuery(INITIAL)

// if (loading) return <Loading />
// if (error) return <p>{`Error! ${error.message}`}</p>
// if (!data) return <p>Not found</p>