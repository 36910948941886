import React from 'react'
import HelmetWrapper from '../components/HelmetWrapper'
import HeaderCompact from '../components/HeaderCompact'
import { localizeString } from '../shared/functions'
import Button from '../components/Button'
import { useMutation } from '@apollo/client'
import { UPDATE_DEVICE_CITY } from '../queries'

export default function SelectCityPage({ data }) {
  const { device, cities } = data
  const pageTitle = localizeString('Выберете город', 'Select your city', device.locale)

  const loadedHandle = ({ updateDevice: { cityId }}) => {
    // Search city by cityId and get alias of city
    const { alias } = cities.find((city) => city.id === cityId)

    // Redirect to the city of device
    window.location = `/${alias}`
  }

  const [updateDevice] = useMutation(UPDATE_DEVICE_CITY, {
    onCompleted: loadedHandle
  })

  const handleClick = ({ id }) => {
    updateDevice(
      {
        variables: {
          id: device.id,
          cityId: id
        }
      }
    )
  }

  return (
    <>
      <HelmetWrapper
        title={pageTitle}
        phone=''
      />
      <HeaderCompact
        device={device}
        locale={device.locale}
      />
      <div className="select-city-page-container">
        <div className="select-city-page-title">
          <h1>{pageTitle}</h1>
        </div>
        <div className="select-city-page-body">
          <ul>
            {
              cities &&
              cities.map((city) =>
                <li key={city.id}>
                  <Button
                    customStyle='select-city-button'
                    title={city.name}
                    handle={() => handleClick(city)}
                  />
                </li>)
            }
          </ul>
        </div>
      </div>
    </>
  )
}
