import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_DELIVERY_TIME, GET_PICKUP_TIME } from '../queries'

export default function useDeliveryAndPickupTime({
	cityId = null,
	restaurantId = null,
}) {
	const [loading, setLoading] = React.useState(true)
	const [data, setData] = React.useState([])
	const [getDeliveryTime] = useLazyQuery(GET_DELIVERY_TIME, {
		variables: {
			cityId,
		},
		fetchPolicy: 'network-only',
		onCompleted: (dataDelivery) => {
			setData(dataDelivery?.getDeliveryTime?.data)
			setLoading(false)
		},
	})
	const [getPickupTime] = useLazyQuery(GET_PICKUP_TIME, {
		variables: {
			restaurantId,
		},
		fetchPolicy: 'network-only',
		onCompleted: (dataPickup) => {
			setData(dataPickup?.getPickupTime?.data)
			setLoading(false)
		},
	})

	const fetches = {
		getDeliveryTime,
		getPickupTime,
	}

	return [loading, data, fetches]
}