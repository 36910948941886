import React from 'react'
import Button from './Button'
import { useHistory } from "react-router-dom"
import { formatCapacity, getPizzaNameSizeByWidth, localizeString } from '../shared/functions'
import { useMutation } from '@apollo/client'
import { GET_CART, UPDATE_IN_CART, REMOVE_FROM_CART } from '../queries'
import { CDN_URL } from '../shared/constants'
import CartTotal from './CartTotal'

export default function CartBody({
  cart,
  products,
  device,
  customerAddress,
  currentCity,
  handleRestaurantIdFromDeliveryCost,
}) {
  const { isEmpty, isDelivery, isPickup, subTotal, discount, grandTotal } = cart
  const { locale } = device

  let history = useHistory()

  if (isEmpty) {
    return (
      <div className="cart-container">
        <div className="cart-title empty">
          <h1>{localizeString('Корзина пуста','Empty cart',locale)}</h1>
        </div>
        <div className="cart-items-list">
          <div className="empty-cart-text-block">
            <p>Вы еще ничего не заказали</p>
            <p>Выберите пиццу из меню на главной странице или повторите предыдущий заказ</p>
            {
              // <p>Для того, чтобы заказать пиццу перейдите на главную страницу</p>
            }
          </div>
          <img src={`${CDN_URL}/static/images/illustrations/cart-empty_1.svg`} className="empty-cart-image" alt="" />
        </div>
        <div className="cart-footer empty">
          <div className="cart-footer-control">
            <Button customStyle="cart-footer-button branded" handle={
              () => { history.goBack() }
            }>Вернуться назад</Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="cart-container">
      <div className="cart-title">
        <h1>
          {localizeString('Корзина','Cart',locale)}
        </h1>
      </div>
      <div className="cart-items-list">
        {
          cart.items &&
          cart.items.map( (item, index) => {
            const product = products.find(p => p.id === item.productId)

            return (
              <CartItem 
                key={index} 
                rowId={index}
                cartId={cart.id}
                productId={item.productId}
                selectedSize={item.selectedSize}
                quantity={item.quantity}
                product={product}
                locale={locale}
              />
            )
          })
        }
      </div>
      <CartTotal
        locale={locale}
        cartId={cart.id}
        cartItems={cart?.items || []}
        isDelivery={isDelivery}
        isPickup={isPickup}
        subTotal={subTotal} 
        discount={discount} 
        grandTotal={grandTotal}
        customerAddress={customerAddress}
        currentCity={currentCity}
        handleRestaurantIdFromDeliveryCost={handleRestaurantIdFromDeliveryCost}
      />
    </div>
  )
}

const CartItem = ({ cartId, rowId, product, selectedSize, quantity, locale  }) => {
  const updateCacheUpdateInCart = (cache, { data: { updateInCart } }) => {
    const { cart: prevCart} = cache.readQuery({ query: GET_CART })
    const { items, totalItems, subTotal, discount, grandTotal } = updateInCart
    const newCart = {
      ...prevCart,
      items,
      totalItems,
      subTotal,
      discount,
      grandTotal
    }

    cache.writeQuery({
      query: GET_CART,
      data: {
        cart: newCart
      }
    })
  }
  const updateCacheRemoveFromCart = (cache, { data: { removeFromCart } }) => {
    const { cart: prevCart} = cache.readQuery({ query: GET_CART })
    const { items, isEmpty, totalItems, subTotal, discount, grandTotal } = removeFromCart
    const newCart = {
      ...prevCart,
      items,
      isEmpty,
      totalItems,
      subTotal,
      discount,
      grandTotal
    }

    cache.writeQuery({
      query: GET_CART,
      data: {
        cart: newCart
      }
    })
  }
  const loadedHandle = (e) => {
    console.log('loadedHandle: ',e)
  }
  const [updateInCart] = useMutation(UPDATE_IN_CART, { 
    update: updateCacheUpdateInCart,
    onCompleted: loadedHandle
  })
  const [removeFromCart] = useMutation(REMOVE_FROM_CART, { 
    update: updateCacheRemoveFromCart,
    onCompleted: loadedHandle
  })

  const handlerIncrease = () => {
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: rowId,
          mode: 'plus'
        },
        // refetchQueries: [
        //   { query: GET_CART }
        // ]
      }
    )
  }

  const handlerDecrease = () => {
    updateInCart(
      { 
        variables: {
          id: cartId,
          rowId: rowId,
          mode: 'minus'
        }
      }
    )
  }

  const handlerRemove = () => {
    removeFromCart(
      { 
        variables: {
          id: cartId,
          rowId: rowId
        }
      }
    )
  }

  const imageURL = product.sizes[selectedSize]['image']['url'] + '?w=' + 256

  let productSize = `${getPizzaNameSizeByWidth(product.sizes[selectedSize]['diameter'], locale)} ${product.sizes[selectedSize]['diameter']} ${localizeString('см, традиционное тесто','cm, traditional crust',locale)}`

  if (product.type === 'sushi') {
    productSize = `${product.sizes[selectedSize]['amount']} ${localizeString('штук','pieces',locale)} ${product.sizes[selectedSize]['capacity']} ${localizeString('грамм','gram',locale)}`
  }

  if (product.type === 'snack') {
    if (product.sizes[selectedSize]['capacity'] != 0) {
      productSize = product.sizes[selectedSize]['capacity'] + ' ' + localizeString('грамм','grams',locale)
    }
    if (product.sizes[selectedSize]['amount'] != 0) {
      productSize = product.sizes[selectedSize]['amount'] + ' ' + localizeString('штук','pieces',locale)
    }
  }

  if (product.type === 'salad') {
    productSize = product.sizes[selectedSize]['capacity'] + ' ' + localizeString('грамм','grams',locale)
  }

  if (product.type === 'drink') {
    productSize = `${formatCapacity(product.sizes[selectedSize]['capacity'], locale)}`
  }

  if (product.type === 'sauce') {
    productSize = `${product.sizes[selectedSize]['capacity']} ${localizeString('грамм','gram',locale)}`
  }

  return (
    <div className="cart-item">
      <div className="cart-item-main">
        <div className="cart-item-image">
          <img src={imageURL} alt="" />
        </div>
        <div className="cart-item-body">
          <h2 className="cart-item-title">{product.title || product.name}</h2>
          <p className="cart-item-desc">{productSize}</p>
        </div>
      </div>
      <div className="cart-item-manage">
        <div className="cart-item-manage-controls">
          {
            quantity > 1
              ? <button
                  className="count-control-button button-minus"
                  onClick={handlerDecrease}>
                  {/* <img src={`${CDN_URL}/static/icons/button-minus.svg`} alt="" /> */}
                  <img src={`/static/icons/button-minus.svg`} alt="" />
                </button>
              : <button
                  className="count-control-button button-remove"
                  onClick={handlerRemove}>
                  {/* <img src={`${CDN_URL}/static/icons/button-remove.svg`} alt="" /> */}
                  <img src={`/static/icons/button-minus.svg`} alt="" />
                </button>
          }
          <span className="count-control-value">{quantity}</span>
          <button
            className="count-control-button button-plus"
            onClick={handlerIncrease}>
            {/* <img src={`${CDN_URL}/static/icons/button-plus.svg`} alt="" /> */}
            <img src={`/static/icons/button-plus.svg`} alt="" />
          </button>
        </div>
        <div className="cart-item-price">
          <span>{`${quantity * product.sizes[selectedSize]['price']} ${localizeString('руб.','rub.',locale)}`}</span>
        </div>
      </div>
    </div>
  )
}
