import React, { useState, useEffect } from 'react'
import { formatPhoneNumber } from '../shared/functions'

export default function Input({ 
  type = 'text', 
  name = '', 
  placeholder = '',
  width = null,
  digitsOnly = false,
  customStyle,
  requiredShow = false,
  // requiredShow = [],
  defaultValue = '',
  readOnly = false,
  maxLength=null,
  handle
}) {
  const [value, setValue] = useState(defaultValue)
  // console.log('requiredShow: ', requiredShow)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const changeHandler = (event) => {
    if (name !== 'phone') {
      let value = event.target.value

      if(digitsOnly){
        value = value.replace(/[^\d]/g,'')
      }

      setValue(value)
      handle && 
      handle({
        name,
        value: value
      })
    } else {
      const phone = formatPhoneNumber(event.target.value)

      setValue(phone)
      handle({
        name,
        value: parseInt(phone.replace(/[^\d]/g,''))
      })
    }
  }
  
  const styles = {}

  if (width) {
    styles.width = width
  }

  return (
    <div 
      className={
        customStyle 
        ? `main-input-wrapper ${customStyle} ${requiredShow ? 'required' : ''} ${value !== '' ? 'hide-span' : ''}` 
        : `main-input-wrapper ${requiredShow ? 'required' : ''} ${value !== '' ? 'hide-span' : ''}`
      }
      style={styles}
    >
      <input
        className="main-input"
        type={type}
        name={name}
        // placeholder={placeholder}
        // defaultValue={defaultValue ? defaultValue : null}
        value={value}
        onChange={changeHandler}
        // autoComplete="none"
        autoComplete="off"
        readOnly={readOnly}
        maxLength={maxLength}
      />
      {
        placeholder &&
        <span>{placeholder}</span>
      }
    </div>
  )
}
