import React from 'react'
import HelmetWrapper from '../components/HelmetWrapper'
import Header from '../components/Header'
import Footer from '../components/Footer'
import OrderSuccess from '../components/OrderSuccess'

const isMobile = false

export default function OrderPage({ data }) {
  const { device, cities, navbar, restaurant } = data

  return (
    <>
      <HelmetWrapper 
        phone={restaurant.phone} 
      />
      <Header 
        cities={cities}
        navbar={navbar}
        restaurant={restaurant}
        device={device}
        isMobile={isMobile}
      />
      <OrderSuccess
        device={device}
        // orderNumber={orderNumber}
      />
      <Footer 
        device={device}
        social={restaurant.social} 
      />
    </>
  )
}