import React from 'react'

export default function InputCheckbox({
  name = '',
  label = '',
  width = null,
  checked = false,
  requiredShow = false,
  customStyle,
  handle
}) {
  const styles = {}

  if (width) {
    styles.width = width
  }

  if (checked) {
    styles.cursor = 'default'
  }

  const checkboxHandler = () => {
    handle && 
    handle({
      name,
      value: !checked
    })
  }

  return (
    <label 
      className={
        customStyle 
        ? `input-checkbox ${customStyle} $${requiredShow ? 'required' : ''}` 
        : `input-checkbox ${requiredShow ? 'required' : ''}`
      }
      style={styles}
    >
      <input
        type="checkbox" 
        className={checked ? "active" : ""}
        name={name}
        // checked={radioState}
        checked={checked}
        onChange={checkboxHandler} 
      />
      <span className={checked ? "checkmark active" : "checkmark"}></span>
      <span className="label-name">{label}</span>
    </label>
  )
}
