import React, { useState, useEffect, useRef } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { MAPBOX_TOKEN } from '../shared/constants'
import { localizeString } from '../shared/functions'

mapboxgl.accessToken = MAPBOX_TOKEN

// const brandColor = '#fa6966'
// const mapMarkerUrl = 'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png'
const mapMarkerUrl = '/static/icons/map-marker.png'

export default function DeliveryZone({ isMobile, device, data, customTitleClass = '' }) {
  const { locale } = device
  const { center, markers, zones } = data
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [lat] = useState(center.latitude || 52.766746)
  const [lng] = useState(center.longitude || 41.416090)
  const [zoom] = useState(12)

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      // style: 'mapbox://styles/mapbox/streets-v11', // style URL
      // style: 'mapbox://styles/mapbox/outdoors-v11',
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom: zoom
    })

    // Add zoom and rotation controls to the map.
    map.current.addControl(new mapboxgl.NavigationControl())

    // Add delivery zone polygon
    map.current.on('load', () => {
      // map.current.addSource('maine', {
      //   'type': 'geojson',
      //   'data': {
      //     'type': 'Feature',
      //     'geometry': {
      //       'type': 'Polygon',
      //       // These coordinates outline Maine.
      //       'coordinates': [
      //           deliveryZone
      //         // polygonTambov.map(a => [a[1],a[0]])
      //         // [
      //         //   [41.4337, 52.7327],
      //         //   [41.4397, 52.7371],
      //         //   [41.4531, 52.73054]
      //         // ]
      //       ]
      //     }
      //   }
      // })

      // Add delivery zones as polygons
      map.current.addSource('maine', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': zones.map((item) => {
            return {
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                'coordinates': [item.coordinates]
              },
              'properties': {
                'id': item.id,
                'title': `${item.title} ${item.cost.amount} руб.`,
                'fill-color': item.style.background.color,
                'fill-opacity': item.style.background.opacity,
                'line-color': item.style.line.color,
                'line-width': 4,
                'line-opacity': item.style.line.opacity,
              }
            }
          }),
        },
      })

      // Add a new layer to visualize the polygon.
      map.current.addLayer({
        'id': 'maine',
        'type': 'fill',
        'source': 'maine', // reference the data source
        'layout': {},
        paint: {
          'fill-color': ['get','fill-color'],
          'fill-opacity': ['get','fill-opacity'],
        },
      })

      // Add a black outline around the polygon.
      map.current.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'maine',
        'layout': {},
        paint: {
          'line-color': ['get','line-color'],
          'line-width': 4,
          'line-opacity': ['get','line-opacity'],
        }
      })

      // Add labels for polygons
      // map.current.addLayer({
      //   'id': 'off-leash-areas',
      //   'type': 'symbol',
      //   'source': 'maine',
      //   'layout': {
      //     'text-field': ['get', 'title'],
      //     'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
      //     }
      //   })

      map.current.addSource('labels', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': zones.map((item) => {
            return {
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': item.labelPosition,
              },
              'properties': {
                'title': item.cost.amount !== 0 ? `${item.title} ${item.cost.amount} руб.` : item.title,
              }
            }
          }),
        }
      })

      // Add a symbol layer
      map.current.addLayer({
        'id': 'labels-of-polygons',
        'type': 'symbol',
        'source': 'labels',
        'layout': {
          // Get the title name from the source's "title" property
          'text-field': ['get', 'title'],
          'text-font': [
            'Open Sans Semibold',
            ],
          // 'text-offset': [0, 1.25],
          'text-anchor': 'top',
          }
        })
      
      // const el = <h1>Hello world</h1>
      // const el = document.createElement('div')
      // el.className = 'marker'
      // el.style.backgroundImage = 'url(' + img_url + ')'
      // new mapboxgl.Marker(el)
      //   .setLngLat([39.840892,54.606945])
      //   .addTo(map.current)

      // Add an image to use as a custom marker
      map.current.loadImage(
        mapMarkerUrl,
        (error, image) => {
          if (error) throw error
          map.current.addImage('custom-marker', image)

          // Add a GeoJSON source with 2 points
          map.current.addSource('points', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': markers.map((item) => {
                return {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': [item.geolocation.longitude,item.geolocation.latitude]
                  },
                  'properties': {
                    'title': item.title,
                  }
                }
              }),
              // {
              //   'type': 'Feature',
              //   'geometry': {
              //     'type': 'Point',
              //     'coordinates': [lng, lat]
              //   },
              //   'properties': {
              //     'title': address
              //   }
              // },
            }
          })

          // Add a symbol layer
          map.current.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'points',
            'layout': {
              'icon-image': 'custom-marker',
              // Get the title name from the source's "title" property
              'text-field': ['get', 'title'],
              'text-font': [
                'Open Sans Semibold',
                // 'Arial Unicode MS Bold'
                ],
              'text-offset': [0, 1.25],
              'text-anchor': 'top'
              }
            })
          }
        )
    })
  }, [])

  return (
    <div className={ isMobile ? "map-component-container" : "map-component-container rounded-map"} id="delivery-map">
      {
        !isMobile && 
        <h2 className={customTitleClass}>{localizeString('Зона доставки','Delivery zone',locale)}</h2>
      }
      <div ref={mapContainer} className="map-container" />
    </div>
  )
}