import React from 'react'
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
import HeaderCompact from '../components/HeaderCompact'
import Button from '../components/Button'
import { localizeString } from '../shared/functions'

export default function NotFoundPage({ data }) {
  const { device } = data
  const { city: cityAlias } = useParams()
  let linkToHome = '/'

  if (cityAlias) {
    linkToHome = `/${cityAlias}`
  }

  return (
    <>
      <Helmet>
        <title>
          {localizeString(
            'Страница не найдена. Ошибка 404',
            'Page not found, Error 404',
            device.locale
          )}
        </title>
      </Helmet>
      <HeaderCompact 
        device={device}
        locale={device.locale} 
      />
      <div className="cart-container">
        <div className="cart-title empty">
          <h1>{localizeString('Страница не найдена','Page not found',device.locale)}</h1>
        </div>
        <div className="cart-items-list">
          <img src="/static/images/illustrations/error_1.svg" className="empty-cart-image" alt="" />
        </div>
        <div className="cart-footer empty">
          <div className="cart-footer-control">
            <Link to={linkToHome}>
              <Button customStyle="cart-footer-button">{localizeString('На главную','Go Home',device.locale)}</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
