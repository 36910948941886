import React, { useState, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ADDRESS_GEOSUGGEST, COORDINATES_GEOCODE } from '../queries'

export default function InputSearchStreet({ 
  type = 'text', 
  name = '', 
  placeholder = '',
  width = null,
  digitsOnly = false,
  customStyle,
  requiredShow = false,
  // requiredShow = [],
  defaultValue = '',
  readOnly = false,
  maxLength = null,
  cityId,
  handleAddress,
}) {
  const [value, setValue] = useState(defaultValue)
  const [isSelected, setIsSelected] = useState(false)
  const [getSuggestions, {
    data: searchResult,
  }] = useLazyQuery(ADDRESS_GEOSUGGEST)
  const [getAddress] = useLazyQuery(COORDINATES_GEOCODE, {
    onCompleted: ({ coordinatesGeocode }) => {
			if (coordinatesGeocode) {
				// Remove __typename "AddressCoordinates" from coordinates object
        const { latitude, longitude } = coordinatesGeocode.coordinates
        handleAddress({
          ...coordinatesGeocode,
          coordinates: {
            latitude,
            longitude,
          },
        })
        setIsSelected(true)
				setValue(coordinatesGeocode.title)
			}
    },
  })

  // Create cache previous streets result
  const cachedData = React.useRef(undefined)
  if (searchResult) cachedData.current = searchResult.addressGeosuggest

  useEffect(() => {
    if (value !== '' && !isSelected) {
      getSuggestions({ variables: { query: value, cityId: cityId } })
    }
  }, [value])

  const changeHandler = (event) => {
      const { value } = event.target
      setValue(value)
      setIsSelected(false)
      handleAddress({ coordinates: null })
  }

  // Get inputWrapperClass
  const inputWrapperClass = getInputWrapperClass(
    customStyle,
    requiredShow,
    value,
    isSelected,
  )

  let styles = {}

  if (width) {
    styles.width = width
  }

  const onSelectItem = async ({ title, isStreet, isHouse, position }) => {
    if (isStreet) {
			setValue(title + ' ')
		}
    if (isHouse) {
			const { latitude, longitude } = position
			getAddress({
        variables: {
          latitude,
          longitude,
        }
      })
		}
  }

  return (
    <div className='search-wrap'>
      <div
        className={inputWrapperClass}
        style={styles}
      >
        <input
          className='search-input'
          type={type}
          name={name}
          value={value}
          onChange={changeHandler}
          autoComplete='none'
          readOnly={readOnly}
          maxLength={maxLength}
        />
        {
          placeholder &&
          <span>{placeholder}</span>
        }
      </div>
      <SuggestionsAddress
        value={value}
        suggestions={cachedData.current || []}
        isActive={!isSelected}
        handle={onSelectItem}
      />
    </div>
  )
}

const SuggestionsAddress = ({ value, suggestions, isActive = false, handle }) => {
  if (value === '') {
    return (
      <div className='search-input-suggestions'>
        <ul></ul>
      </div>
    )
  }

  return (
    <div className={`search-input-suggestions ${isActive ? 'active' : ''}`}>
      <ul>
        {
          suggestions &&
          suggestions.map((address, index) => 
            <SuggestItem
              {...address}
              key={index}
              handle={handle}
            />
          )
        }
      </ul>
    </div>
  )
}

const SuggestItem = ({
  title = '',
  subtitle = '',
  isStreet = false,
  isHouse = false,
  position = null,
  handle,
}) => {
  return (
		<li onClick={() => handle({
      title,
      subtitle,
      isStreet,
      isHouse,
      position,
    })}>
			<span className='address-title'>{title}</span>
			<span className='address-subtitle'>{subtitle}</span>
		</li>
	)
}

const getInputWrapperClass = (customStyle, requiredShow, value, isSelected) => {
  let inputWrapperClass = 'search-input-wrapper'

  if (customStyle) {
    inputWrapperClass = inputWrapperClass + ` ${customStyle}`
  }

  if (requiredShow) {
    inputWrapperClass = inputWrapperClass + ` required`
  }

  if (value !== '') {
    inputWrapperClass = inputWrapperClass + ` hide-span`
  }

  if (value !== '') {
    if (isSelected) {
      inputWrapperClass = inputWrapperClass + ` not-active`
    }
  } else {
    inputWrapperClass = inputWrapperClass + ` not-active`
  }

  return inputWrapperClass
}
