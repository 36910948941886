import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import './styles/nullstyle.scss'
import './styles/main.sass'
import { INITIAL, PRE_INITIAL } from './queries'
import { useQuery } from '@apollo/client'
import SelectCityPage from './pages/SelectCityPage'
import HomePage from './pages/HomePage'
import CartPage from './pages/CartPage'
import StaticPage from './pages/StaticPage'
import { PoliticsOfPrivacy, PrivacyPolicy, UserAgreement } from './shared/staticContent'
import NotFoundPage from './pages/NotFoundPage'
import OrderPage from './pages/OrderPage'
import ContactsPage from './pages/ContactsPage'
import AboutUsPage from './pages/AboutUsPage'

const staticPages = [
  {
    title: 'Пользовательское соглашение',
    link: '/:city/user-agreement',
    content: <UserAgreement />
  },
  {
    title: 'Политика обработки персональных данных',
    link: '/:city/personal-data-processing-policy',
    content: <PrivacyPolicy />
  },
  {
    title: 'Согласие на обработку персональных данных',
    link: '/:city/politics-of-privacy',
    content: <PoliticsOfPrivacy />
  }
]

export default function App() {
  return (
    <Switch>
      <Route exact path='/' children={
        <RouteWrapperNoCity>
          <SelectCityPage isMobile={false} />
        </RouteWrapperNoCity>
      } />
      <Route path="/:city/order/:orderId" children={
          <RouteWrapper>
            <OrderPage />
          </RouteWrapper>
        } />
      <Route path="/:city/order">
        <RouteWrapper>
          <CartPage />
        </RouteWrapper>
      </Route>
      {
        staticPages.map((page, index) =>
          <Route
            key={index}
            path={page.link}
            children={
              <RouteWrapper>
                <StaticPage title={page.title} content={page.content} isMobile={false} />
              </RouteWrapper>
            }
          />
        )
      }
      <Route path="/:city/contacts" children={
        <RouteWrapper>
          <ContactsPage isMobile={false} />
        </RouteWrapper>
      } />
      <Route path="/:city/about-us" children={
        <RouteWrapper>
          <AboutUsPage isMobile={false} />
        </RouteWrapper>
      } />
      <Route path='/:city' children={
        <RouteWrapper>
          <HomePage />
        </RouteWrapper>
      } />
      <Route path="/*" children={
        <RouteWrapperNoCity>
          <NotFoundPage isMobile={false} />
        </RouteWrapperNoCity>
      } />
    </Switch>
  )
}

const RouteWrapperNoCity = (props) => {
  const { data } = useQuery(PRE_INITIAL)

  if (!data) return null

  return React.cloneElement(props.children, { data })
}

const RouteWrapper = (props) => {
  const { city } = useParams()
  const { data } = useQuery(INITIAL, {
    variables: { city: city }
  })

  if (!data) return null

  const restaurant = data.restaurants.find(item => item.city.alias === city)

  return React.cloneElement(props.children, {
    data: {
      ...data,
      restaurant
    }
  })
}
