import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import moment from 'moment'
import Button from './Button'
import DropdownModern from './DropdownModern'
import Input from './Input'
import InputRadio from './InputRadio'
import InputCheckbox from './InputCheckbox'
import TextArea from './TextArea'
import InputSearchStreet from './InputSearchStreet'
import ErrorIcon from '../assets/icons/error.svg'
import { localizeString, requiredCheck, validateOrder } from '../shared/functions'
import { useMutation } from '@apollo/client'
import { CHANGE_DELIVERY_TYPE, GET_CART, NEW_ORDER, GET_ORDER } from '../queries'
import useDeliveryAndPickupTime from '../hooks/useDeliveryAndPickupTime'

export default function OrderBody({
  isMobile,
  cart,
  restaurants,
  restaurantId,
  restaurantIdFromDeliveryCost,
  device,
  cities,
  handleCustomerAddress,
}) {
  const { city: cityAlias } = useParams()
  const currentCity = cities.find((city) => city.alias === cityAlias)
  const updateCacheOrder = (cache, { data: { createOrder } }) => {
    // we place order information to cache now,
    // and when customer go to order page, state already has data
    cache.writeQuery({
      query: GET_ORDER,
      data: {
        order: createOrder
      },
      variables: {
        id: createOrder.id,
      },
    })

    const sharedObject = {
      amount: 0,
      formatted: localizeString('0 руб.', '0 rub.', device.locale)
    }

    // Временно отключено из-за неизвестной проблемы в <CartButton />
    // Clear the cart
    // cache.writeQuery({
    //   query: GET_CART,
    //   data: {
    //     cart: {
    //       isEmpty: true,
    //       isDelivery: true,
    //       isPickup: false,
    //       items: [],
    //       totalItems: 0,
    //       totalUniqueItems: 0,
    //       subTotal: sharedObject,
    //       discount: 0,
    //       grandTotal: sharedObject
    //     }
    //   }
    // })

    // Временно отключил это, при переходе происходит ошибка в <CartButton />
    // history.push("/order/"+createOrder.id)
    // Проблема появилась после добавления суши на сайт
    window.location = `/${cityAlias}/order/${createOrder.id}`
  }
  const [placeNewOrder, { error }] = useMutation(NEW_ORDER, { update: updateCacheOrder })
  const updateCacheUpdateInCart = (cache, { data: { changeDeliveryType } }) => {
    const { cart: prevCart} = cache.readQuery({ query: GET_CART })
    const { isDelivery, isPickup, subTotal, discount, grandTotal } = changeDeliveryType

    const newCart = {
      ...prevCart,
      isDelivery,
      isPickup,
      subTotal,
      discount,
      grandTotal
    }

    cache.writeQuery({
      query: GET_CART,
      data: {
        cart: newCart
      }
    })
  }
  const [changeDeliveryType] = useMutation(CHANGE_DELIVERY_TYPE, { update: updateCacheUpdateInCart })
  const [orderFieldsRequired, setOrderFieldsRequired] = useState([])
  const [orderErrorMessages,setOrderErrorMessages] = useState([])
  const [orderDataToSend, setOrderDataToSend] = useState({
    name: '',
    phone: null,
    address: {
      street: '',
      house: '',
      apartment: '',
      entrance: '',
      floor: '',
      doorCode: '',
      comment: '',
      coordinates: null,
    },
    restaurantId: null,
    time: {
      mode: '',
      preferredInterval: '',
      selectedDate: '',
    },
    payment: {
      mode: '',
      cashbackValue: 0,
    },
    notifications: {
      userAgreement: true,
      privacyPolicy: true,
      sms: true,
      email: true,
    },
    comment: '',
    channel: isMobile ? 'webMobile' : 'webDesktop',
  })

  const { isEmpty, isDelivery, isPickup } = cart
  const { locale } = device

  // if cart is empty => hide order block
  if (isEmpty) return null

  React.useEffect(() => {
    setOrderDataToSend({
      ...orderDataToSend,
      restaurantId: restaurantIdFromDeliveryCost,
    })
    // Forced add restaurantId (Saransk only)
    if (currentCity.alias === 'saransk') {
      setOrderDataToSend({
      ...orderDataToSend,
        restaurantId,
      })
    }
  }, [restaurantIdFromDeliveryCost])

  const inputHandler = ({ name, value }) => {
    setOrderDataToSend({ ...orderDataToSend, [name]: value })
  }

  const orderTypeHandler = (value) => {
    changeDeliveryType(
      { 
        variables: {
          isDelivery: value === 'delivery' ? true : false,
          isPickup: value === 'pickup' ? true : false,
        },
      }
    )
    // Clear selected restaurantId
    setOrderDataToSend({
      ...orderDataToSend,
      restaurantId: null,
    })
    // Clear customer address in orderForm
    setOrderDataToSend({
      ...orderDataToSend,
      address: {
        street: '',
        house: '',
        apartment: '',
        entrance: '',
        floor: '',
        doorCode: '',
        comment: '',
        coordinates: null,
      },
    })
  }

  const universalHandlerBlock = ({ blockName, blockData }) => {
    setOrderDataToSend({
      ...orderDataToSend,
      [blockName]: blockData
    })
  }

  // Confirm order clicked
  const confirmHandler = (event) => {
    event.preventDefault()
    const { validationStatus, errorMessages, requiredFields } = validateOrder(orderDataToSend,isDelivery,isPickup)
    // console.log(validationStatus, errorMessages, requiredFields)

    if (validationStatus) {
      placeNewOrder({
        variables: {
          input: {
            ...orderDataToSend,
            cartId: cart.id,
            type: isDelivery ? 'delivery' : 'pickup'
          }
        }
      })
    }
    console.log('[OrderBody] orderDataToSend:',{
      ...orderDataToSend,
      cartId: cart.id,
      type: isDelivery ? 'delivery' : 'pickup'
    })

    setOrderErrorMessages(errorMessages)
    setOrderFieldsRequired(requiredFields)
  }

  const blockProps = {
    handle: universalHandlerBlock,
    locale,
    orderFieldsRequired
  }

  return (
    <div className="order-container">
      <div className="order-body-wrapper">
        <div className="order-body mb2">
          <div className="order-contact-form">
            <div className="row">
              <h2>{localizeString('Контактная информация','Contact form',locale)}</h2>
            </div>
            <div className="row order-contact-form-content">
              <Input 
                placeholder={
                  localizeString('Ваше имя','Your name',locale)
                }
                name="name"
                customStyle="contact-form-name"
                // customStyle="mr3"
                requiredShow={requiredCheck('name',orderFieldsRequired)}
                // width={9+14+1+1+'rem'}
                handle={inputHandler}
              />
              <Input 
                placeholder={
                  localizeString('Номер телефона','Phone number',locale)
                }
                name="phone"
                requiredShow={requiredCheck('phone',orderFieldsRequired)}
                handle={inputHandler}
              />
            </div>
          </div>
          <div className="order-type">
            <ul>
              {
                orderTypes.map((item, index) => {
                  let active = ''

                  if (item.alias === 'delivery' && isDelivery) {
                    active = 'active'
                  }

                  if (item.alias === 'pickup' && isPickup) {
                    active = 'active'
                  }

                  return (
                    <li 
                      key={index} 
                      className={active} 
                      onClick={
                        () => { orderTypeHandler(item.alias) }
                      }
                    >
                      {item.name[locale]}
                    </li>
                  )
                })
              }
            </ul>
            {
              isPickup
                ? (
                  <p className="discount-description">
                    {
                      localizeString(
                        'Забирая пиццу самостоятельно, вы получаете скидку 100 рублей к каждой пицце',
                        `By choosing the takeaway you've got a 100 rub. discount for each pizza`,
                        locale
                      )
                    }
                  </p>
                )
                : null
              }
          </div>
          {
            isDelivery
              ? <DeliveryBlock
                {...blockProps}
                currentCity={currentCity}
                handleCustomerAddress={handleCustomerAddress}
              />
              : <PickupBlock 
                  {...blockProps}
                  restaurants={restaurants}
                />
          }
          <TimeBlock
            {...blockProps}
            isDelivery={isDelivery}
            restaurantId={restaurantId}
            cityId={currentCity.id}
          />
          <PaymentBlock
            {...blockProps}
            isDelivery={isDelivery}
          />
          <CommentBlock
            {...blockProps}
          />
          <NotificationsBlock
            {...blockProps}
          />
          <div className="row">
            <Button
              customStyle='order-button-complete branded'
              handle={confirmHandler}
            >
              {localizeString('Заказать', 'Submit', locale)}
            </Button>
          </div>
          <ErrorMessagesBlock
            locale={locale}
            orderErrorMessages={orderErrorMessages}
            show={orderErrorMessages?.length ? true : false}
          />
        </div>
      </div>
    </div>
  )
}

const orderTypes = [
  {
    name: {
      ru: 'Доставка',
      en: 'Delivery'
    },
    alias: 'delivery'
  },
  {
    name: {
      ru: 'Самовывоз',
      en: 'Pickup',
    },
    alias: "pickup" 
  }
]

const DeliveryBlock = ({
  handle,
  orderFieldsRequired,
  locale,
  currentCity,
  handleCustomerAddress,
}) => {
  const [form, setForm] = useState({
    street: '',
    house: '',
    apartment: '',
    entrance: '',
    doorCode: '',
    floor: '',
    comment: '',
  })
  const [requiredStatus, setRequiredStatus] = useState({
    street: false,
    house: false
  })
  const inputListLineOne = [
    {
      name: 'apartment', 
      placeholder: {
        ru: 'Квартира',
        en: 'Apartment'
      }, 
      customStyle: 'apartment-form',
      digitsOnly: true
    },
    {
      name: 'entrance', 
      placeholder: {
        ru: 'Подъезд',
        en: 'Entrance'
      }, 
      customStyle: 'entrance-form',
      digitsOnly: true
    },
  ]
  const inputListLineTwo = [
    {
      name: 'floor', 
      placeholder: {
        ru: 'Этаж',
        en: 'Floor'
      }, 
      customStyle: 'floor-form',
      digitsOnly: true
    },
    {
      name: 'doorCode', 
      placeholder: {
        ru: 'Код домофона',
        en: 'Door code'
      }, 
      customStyle: 'doorCode-form'
    }
  ]

  useEffect(() => {
    if (requiredCheck('address',orderFieldsRequired)) {
      const { subFields } = orderFieldsRequired.filter(item => item.field === 'address')[0]
      let requiredStreet = false,
          requiredHouse = false

      if (requiredCheck('street',subFields)) {
        requiredStreet = true
      }

      if (requiredCheck('house',subFields)) {
        requiredHouse = true
      }

      setRequiredStatus({
        street: requiredStreet,
        house: requiredHouse
      })
    }
    return () => {
      setRequiredStatus({
        street: false,
        house: false
      })
    }
  }, [orderFieldsRequired])

  const inputHandler = ({ name, value }) => {
    setForm({ ...form, [name]: value })
    handle &&
    handle({
      blockName: 'address',
      blockData: { ...form, [name]: value }
    })
  }

  const addressHandler = ({ street, house, coordinates, }) => {
    // console.log('[DeliveryBlock] addressHandler:', street, house, coordinates)
    setForm({
      ...form,
      street,
      house,
      coordinates,
    })
    // Handle to orderBody form
    handle &&
    handle({
      blockName: 'address',
      blockData: {
        ...form,
        street,
        house,
        coordinates,
      }
    })
    // Handle to cartBody
    handleCustomerAddress &&
    handleCustomerAddress({
      street,
      house,
      coordinates,
    })
  }

  return <div className="order-delivery-block">
    <div className="city-and-street-line">
      <Input 
        name="city"
        defaultValue={currentCity.name || 'Тамбов'}
        customStyle="city-form"
        // width={9+14+1+1+'rem'}
        readOnly={true}
      />
      <InputSearchStreet
        placeholder={localizeString('Улица и номер дома','Street and house number',locale)}
        name='street'
        requiredShow={requiredStatus.street}
        customStyle='street-form'
        cityId={currentCity.id}
        handleAddress={addressHandler}
      />
    </div>
    <div className="row address-lines mb3">
      <div className="address-line-one">
        {
          inputListLineOne.map( (item, index) => {
            return(
              <Input
                key={index}
                placeholder={item.placeholder[locale]}
                name={item.name}
                customStyle={item.customStyle}
                requiredShow={item.requiredShow || false }
                width={item.width}
                handle={inputHandler}
              />
            )
          })
        }
      </div>
      <div className="address-line-two">
        {
          inputListLineTwo.map( (item, index) => {
            return(
              <Input
                key={index}
                placeholder={item.placeholder[locale]}
                name={item.name}
                customStyle={item.customStyle}
                requiredShow={item.requiredShow || false }
                width={item.width}
                handle={inputHandler}
              />
            )
          })
        }
      </div>
    </div>
    {/* <div className="row mb3">
      <TextArea 
        placeholder={
          localizeString('Комментарий к адресу', 'Comment for this address', locale)
        }
        name="comment"
        handle={inputHandler}
        rows={2}
        maxLength={200}
      />
    </div> */}
  </div>
}

const PickupBlock = ({ locale, handle, restaurants, orderFieldsRequired }) => {
  const [addresses, setAddresses] = useState([
    // { title: restaurant.address, checked: false }
    ...restaurants.map((r) => ({
      id: r.id,
      label: r.address,
      checked: false,
    }))
  ])
  const [visible, setVisible] = useState(false)
  // const [selected, setSelected] = useState(0)
  // const radioHandler = (index) => setSelected(index)

  const radioHandler = ({ rowId }) => {
    const newAddresses = addresses.map((item, index) => {
        return {
          ...item,
          checked: rowId === index ? true : false
        }
      }
    )

    setAddresses(newAddresses)
    setVisible(true)
    // Get restaurant by rowId
    const selectedRestaurant = restaurants.find((_, index) => index === rowId)
    handle &&
    handle({
      blockName: 'restaurantId',
      blockData: selectedRestaurant.id,
    })
    // handle(restaurantId)
  }

  return <>
    {/* <div className="row">
      <h2>Выберете адрес пиццерии</h2>
    </div> */}
    <div className="row mb3">
      <ul className="order-when">
        { 
          addresses.map((address, index) => {
            return (
              <li key={index}>
                  <InputRadio
                    label={address.label}
                    rowId={index}
                    handle={radioHandler}
                    requiredShow={requiredCheck('restaurant', orderFieldsRequired)}
                    checked={address.checked}
                  />
              </li>
            )
          })
        }
      </ul>
    </div>
  </>
}

const TimeBlock = ({
  isDelivery,
  cityId,
  restaurantId,
  locale,
  orderFieldsRequired,
  handle,
}) => {
  const [loading, data, fetches] = useDeliveryAndPickupTime({ cityId, restaurantId, })
  const [mode, setMode] = React.useState('')
  const [selectedDate, setDate] = React.useState('')
	const [selectedInterval, setInterval] = React.useState('')

	React.useEffect(() => {
		if (data) {
			setDate(data[0]?.value || '')
			setInterval(data[0]?.timeIntervals[0] || '')
		}
	},[data])

  useEffect(() => {
    if(mode === 'selectTime') {
      isDelivery &&
      fetches.getDeliveryTime()
      !isDelivery &&
      fetches.getPickupTime()
    }
  }, [mode])

  useEffect(() => {
    isDelivery &&
    fetches.getDeliveryTime()
    !isDelivery &&
    fetches.getPickupTime()
    if (data) {
      setMode('')
      setDate('')
      setInterval('')
		}
  }, [isDelivery])

  const radioHandler = ({ name }) => {
    setMode(name)
    handle &&
    handle({
      blockName: 'time',
      blockData: {
        mode: name,
        selectedDate,
        preferredInterval: selectedInterval,
      }
    })
  }

  const dropdownHandler = ({ name, index }) => {
    if (name === 'selectedDate') {
      const date = data[index]
      setDate(date.value)
      handle &&
      handle({
        blockName: 'time',
        blockData: {
          mode,
          selectedDate: date.value,
          preferredInterval: date.timeIntervals[0],
        }
      })
    }
    if (name === 'preferredInterval') {
      const date = data.find((d) => d.value == selectedDate)
      if (date) {
        const preferredInterval = date.timeIntervals[index]
        setInterval(preferredInterval)
        handle &&
        handle({
          blockName: 'time',
          blockData: {
            mode,
            selectedDate,
            preferredInterval,
          }
        })
      }
    }
  }

  return <>
    <div className="row">
      <h2>
        {
          isDelivery &&
          localizeString('Время доставки','Delivery time',locale)
        }
        {
          !isDelivery &&
          localizeString('Время получения','Pick up time',locale)
        }
      </h2>
    </div>
    <div className={`row ${mode === 'selectTime' ? 'mb1' : 'mb3'}`}>
      <ul className="order-when">
        <li>
          <InputRadio
            name='asSoonAsPossible'
            label={localizeString('Как можно скорее','As soon as possible',locale)}
            handle={radioHandler} 
            requiredShow={requiredCheck('time', orderFieldsRequired)}
            checked={mode === 'asSoonAsPossible'}
          />
        </li>
        <li>
          <InputRadio
            name='selectTime'
            label={localizeString('Выбрать время','Select the time',locale)}
            handle={radioHandler}
            requiredShow={requiredCheck('time', orderFieldsRequired)}
            checked={mode === 'selectTime'}
          />
        </li>
      </ul>
    </div>
    {
      loading &&
      mode === 'selectTime' &&
      <div className="row mb3 order-time-dropdowns">
        <DropdownModern 
          items={['']}
          defaultSelected={0}
          largeButton={true}
          name='selectedDate' // date
        />
        <DropdownModern 
          items={[``]}
          defaultSelected={0}
          largeButton={true}
          customClass='ml3 order-select-interval'
          name='preferredInterval' // time
        />
      </div>
    }
    {
      !loading &&
      mode === 'selectTime' &&
      <div className="row mb3 order-time-dropdowns">
        <DropdownModern 
          items={data.map((item) => item.label)}
          defaultSelected={0}
          largeButton={true}
          name='selectedDate' // date
          handle={dropdownHandler}
        />
        <DropdownModern 
          items={data.find((d) => d.value === selectedDate)?.timeIntervals}
          defaultSelected={0}
          largeButton={true}
          customClass='ml3 order-select-interval'
          name='preferredInterval' // time
          handle={dropdownHandler}
        />
      </div>
    }
  </>
}

const PaymentBlock = ({ isDelivery, locale, handle, orderFieldsRequired }) => {
  const [orderPayments, setOrderPayment] = useState([
    { name: 'cash', checked: false },
    { name: 'cardByPickup', checked: false },
    { name: 'cardOnline', checked: false }
  ])
  const [cashbackValue, setCashbackValue] = useState(0)
  const [currentSelectedName, setCurrentSelectedName ] = useState('')

  const radioHandler = ({ name }) => {
    const newOrderPayments = orderPayments.map((item) => {
        return {
          ...item,
          checked: name === item.name ? true : false
        }
      }
    )
    setOrderPayment(newOrderPayments)
    setCurrentSelectedName(name)
    handle &&
    handle({
      blockName: 'payment',
      blockData: {
        mode: name,
        cashbackValue: name === 'cash' ? +cashbackValue : 0
      }
    })
  }

  const cashbackHandler = ({ value }) => {
    setCashbackValue(value)
    handle &&
    handle({
      blockName: 'payment',
      blockData: {
        mode: currentSelectedName,
        cashbackValue: currentSelectedName === 'cash' ? +value : ''
      }
    })
  }

  return <>
    <div className="row">
      <h2>
        {
          localizeString('Способ оплаты','Payment',locale)
        }
      </h2>
    </div>
    <div className="row mb3">
      <ul className="payment-type">
        <li>
          <InputRadio
            name="cash"
            label={
              localizeString('Наличными','Cash',locale)
            }
            handle={radioHandler} 
            requiredShow={requiredCheck('payment',orderFieldsRequired)}
            checked={orderPayments[0]['checked']} 
          />
        </li>
        {
          orderPayments[0]['checked'] 
          ? <li className="cashback-option mb1">
              <p>
                {
                  localizeString('Нужна сдача с купюры','Need change from',locale)
                }
              </p>
              <Input 
                placeholder='1000'
                name='cashbackValue'
                digitsOnly={true}
                maxLength={4}
                width={10+'rem'}
                handle={cashbackHandler}
              />
            </li> 
          : null
        }
        {
          !isDelivery &&
          <li>
            <InputRadio
              name="cardByPickup"
              label={
                localizeString(
                  isDelivery ? `Картой курьеру` : `Картой в ресторане`,
                  `Card ${isDelivery ? '(courier)' : '(restaurant)'}`,
                  locale
                )
              }
              handle={radioHandler}
              requiredShow={requiredCheck('payment',orderFieldsRequired)}
              checked={orderPayments[1]['checked']} 
            />
          </li>
        }
        {/* <li>
          <InputRadio
            name="cardOnline"
            label={
              localizeString('Картой на сайте','Online payment',locale)
            }
            handle={radioHandler}
            requiredShow={requiredCheck('payment',orderFieldsRequired)}
            checked={orderPayments[2]['checked']} 
          />
        </li> */}
      </ul>
    </div>
  </>
}

const CommentBlock = ({ handle, locale }) => {
  const [form, setForm] = useState({
    comment: ''
  })

  const inputHandler = ({ name, value }) => {
    setForm({ ...form, [name]: value })
    handle &&
    handle({
      blockName: 'comment',
      // blockData: { ...form, [name]: value }
      blockData: value
    })
  }

  return (
    <>
      <div className="row">
        <h2>
          {
            localizeString('Комментарий','Comment',locale)
          }
        </h2>
      </div>
      <div className="row order-comment mb3">
        <TextArea 
          placeholder={
            localizeString('Комментарий к заказу', 'Comment for this order', locale)
          }
          name="comment"
          customStyle='mb1'
          handle={inputHandler}
          rows={3}
          maxLength={200}
        />
      </div>
    </>
  )
}

const NotificationsBlock = ({ handle, locale, orderFieldsRequired }) => {
  const [notifications, setТotifications] = useState({
    userAgreement: true,
    privacyPolicy: true,
    sms: true,
    email: true,
  })
  const [requiredStatus, setRequiredStatus] = useState({
    userAgreement: false,
    privacyPolicy: false
  })
  const notificationInputs = [
    {
      alias: 'privacyPolicy',
      requiredShow: requiredStatus.privacyPolicy,
      title: <>
              {localizeString('Даю ','I agree with the ',locale)}
              <a href={`/privacy-policy`} target="_blank" rel='noopener noreferrer'>{localizeString('Согласие', 'Privacy Policy', locale)}</a>
              {localizeString(' на обработку моих персональных данных',' to process my personal data',locale)}
            </>
    },
    {
      alias: 'userAgreement',
      requiredShow: requiredStatus.userAgreement,
      title: <>
              {localizeString('Принимаю ','I agree with the ',locale)}
              <Link to="/user-agreement">{localizeString('Пользовательское соглашение','Terms of Service',locale)}</Link>
            </>
    },
    { 
      alias: 'sms',
      requiredShow: false,
      title: localizeString('Хочу получать SMS уведомления','I want to receive SMS notifications',locale), 
    },
    {
      alias: 'email',
      requiredShow: false,
      title: localizeString('Хочу получать E-mail уведомления','I want to receive email notifications',locale)
    }
  ]

  useEffect(() => {
    if (requiredCheck('notifications',orderFieldsRequired)) {
      const { subFields } = orderFieldsRequired.filter(item => item.field === 'notifications')[0]
      let requiredUserAgreement = false,
          requiredPrivacyPolicy = false

      if (requiredCheck('userAgreement',subFields)) {
        requiredUserAgreement = true
      }

      if (requiredCheck('privacyPolicy',subFields)) {
        requiredPrivacyPolicy = true
      }

      setRequiredStatus({
        userAgreement: requiredUserAgreement,
        privacyPolicy: requiredPrivacyPolicy
      })
    }
    
    return () => {
      setRequiredStatus({
        userAgreement: false,
        privacyPolicy: false
      })
    }
  }, [orderFieldsRequired])

  const checkboxHandler = ({ name, value }) => {
    setТotifications({ 
      ...notifications, 
      [name]: value
    })
    handle &&
    handle({
      blockName: 'notifications',
      blockData: { 
        ...notifications, 
        [name]: value
      }
    })
  }

  return (
    <>
      <div className="row order-notifications mb2">
        <ul>
          {
            notificationInputs.map((item, index) => {
              return (
                <li key={index}>
                  <InputCheckbox
                    name={item.alias}
                    label={item.title}
                    handle={checkboxHandler}
                    requiredShow={item.requiredShow}
                    checked={notifications[item.alias]} 
                  />
                </li>
              )
            })
          }
        </ul>
      </div>
    </>
  )
}

const ErrorMessagesBlock = ({ locale, orderErrorMessages, show }) => {
  return (
    <div className={`row order-errors${show ? ' show' : ''}`}>
      <ul>
        {
          orderErrorMessages.map((item,index) => <li key={index}><i><img src={ErrorIcon} alt="" /></i><span>{item[locale]}</span></li>)
        }
      </ul>
    </div>
  )
}
