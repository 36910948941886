import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HelmetWrapper from '../components/HelmetWrapper'
import { localizeString } from '../shared/functions'
import { CDN_URL } from '../shared/constants'

export default function AboutUsPage({ data, isMobile = false }) {
  const { device, cities, navbar, restaurant } = data
  const { locale } = device
  const title = localizeString('О компании','About Us',locale)

  return (
    <>
      <HelmetWrapper
        title={title}
        phone={restaurant.phone}
      />
      <Header
        cities={cities}
        navbar={navbar}
        restaurant={restaurant}
        device={device}
        locale={locale}
        isMobile={isMobile}
      />
      <div className="about-us-page-container">
        <div className="about-us-page-title">
          <h1>{title}</h1>
        </div>
        <div className="about-us-page-body">
          <h2>Почему пицца пятница?</h2>
          <h2>Как нам удаётся делать такую большую пиццу по доступной цене, и не потерять в качестве?</h2>
          <h2>Наша команда</h2>
          <div className="image-wide">
            <img src="/static/images/other/our-team-wide.jpg" alt="" />
            {/* <img src={`${CDN_URL}/static/images/other/our-team.jpg`} alt="" /> */}
          </div>
          <div className="our-team-block">
            <div className="staff-photo">
              <img src="/static/images/other/team-01.jpg" alt="" />
            </div>
            <div className="staff-photo">
              <img src="/static/images/other/team-02.jpg" alt="" />
            </div>
            <div className="staff-photo">
              <img src="/static/images/other/team-03.jpg" alt="" />
            </div>
            <div className="staff-photo">
              <img src="/static/images/other/team-04.jpg" alt="" />
            </div>
            <div className="staff-photo">
              <img src="/static/images/other/team-05.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer
        device={device}
        locale={locale}
        social={restaurant.social}
      />
    </>
  )
}
