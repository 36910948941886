export const stringToSlug = (str) => {
  return str.toLowerCase().split(" ").join("-")
}

export const slugToString = (str) => {
  return str.toLowerCase().split("-").join(" ")
}

export const  declOfNum = (number, titles) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export const declOfPrice = (worth) => {
  return declOfNum(worth, ['рубль', 'рубля', 'рублей'])
}

export const localizeString = (ru,en,locale) => {
  return locale === 'ru' ? ru : en
}

export const formatCapacity = (number, locale) => {
  if (number < 1000) {
    return localizeString(`${number} мл.`,`${number} ml.`,locale)
  }
  return localizeString(`${number/1000} л.`,`${number/1000} liter`,locale)
}

export const randomIntFromInterval = (min, max) => { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const calculatePersons = (diameter) => {
  switch (diameter) {
    case 33:
      return '2 - 3'
    case 41:
      return '3 - 5'
    default:
      return '1'
  }
}

export const getPizzaNameSizeByWidth = (diameter, locale) => {
  switch (diameter) {
    case 33:
      return localizeString('Средняя','Medium',locale)
    case 41:
      return localizeString('Большая','Large',locale)
    default:
      return localizeString('Средняя','Medium',locale)
  }
}

// check the order data (validation)
export const validateOrder = (
  {
    name,
    phone,
    address,
    restaurant,
    time,
    payment,
    notifications
  },
  isDelivery,
  isPickup
) => {
  let status = true,
      message = [],
      required = []

  // validation name filed
  if (name === '') {
    status = false
    message.push({
      ru: 'Поле имя должно быть заполнено',
      en: 'The name field is empty'
    })
    required.push({ field: 'name' })
  }
  // console.log('phone.length: ', phone.length)

  // validation phone filed, 16 length for formatted || 11 length for clear
  if (phone === null || phone.toString().length < 11) {
    status = false
    message.push({
      ru: 'Введите корректный номер телефона',
      en: 'Enter your phone number'
    })
    required.push({ field: 'phone' })
  }

  // Address validation
  if (isDelivery) {
    let requiredSubFileds = []

    if (address.street === '') {
      status = false
      message.push({
        // ru: 'Укажите адрес доставки',
        // en: 'Choose your delivery address'
        ru: 'Укажите улицу для доставки',
        // en: 'Choose your street name'
        en: 'Enter the name of the street'
      })
      requiredSubFileds.push({ field: 'street' })
    }

    if (address.house === '') {
      status = false
      message.push({
        // ru: 'Укажите адрес доставки',
        // en: 'Choose your delivery address'
        ru: 'Укажите номер дома',
        // en: 'Enter your house number'
        en: 'Enter the house number'
      })
      requiredSubFileds.push({ field: 'house' })
    }

    if (requiredSubFileds.length !== 0) {
        required.push({ 
          field: 'address',
          subFields: requiredSubFileds
      })
    }
  }

  // validation restaurant filed (pickup only)
  if (isPickup) {
    if (restaurant === '') {
      status = false
      message.push({
        ru: 'Выберете ресторан',
        // en: 'Choose the restaurant address',
        en: 'Choose the pickup address'
      })
      required.push({ field: 'restaurant' })
    }
  }

  // validation time filed
  if (time.mode === '') {
    status = false
    message.push({
      ru: 'Выберете время',
      en: isDelivery ? 'Choose the delivery time' : 'Choose the pickup time'
      // en: 'Choose the time'
    })
    required.push({ field: 'time' })
  }

  // validation payment filed
  if (payment.mode === '') {
    status = false
    message.push({
      ru: 'Выберете способ оплаты',
      en: 'Choose the payment type'
    })
    required.push({ field: 'payment' })
  }

// console.log('notifications.userAgreement !== true || notifications.privacyPolicy !== true',notifications.userAgreement !== true, notifications.privacyPolicy !== true)

  // validation notifications filed (userAgreement & privacyPolicy)
  if (notifications.userAgreement === false || notifications.privacyPolicy === false) {
    let requiredSubFileds = []

    if (notifications.userAgreement === false) {
      status = false
      message.push({
        // ru: 'Укажите адрес доставки',
        // en: 'Choose your delivery address'
        ru: 'Необходимо принять Пользовательское соглашение',
        en: 'You must agree with the User Agreement'
      })
      requiredSubFileds.push({ field: 'userAgreement' })
    }

    if (notifications.privacyPolicy === false) {
      status = false
      message.push({
        // ru: 'Укажите адрес доставки',
        // en: 'Choose your delivery address'
        ru: 'Необходимо Ваше согласие на обработку персональных данных',
        en: 'You must agree with the Privacy Policy'
      })
      requiredSubFileds.push({ field: 'privacyPolicy' })
    }

    if (requiredSubFileds.length !== 0) {
        required.push({ 
          field: 'notifications',
          subFields: requiredSubFileds
      })
    }
  }

  return {
    validationStatus: status,
    errorMessages: message,
    requiredFields: required
  }
} 

export const formatPhoneNumber = (value) => {
  value = value
    .replace('+7', '')
    .replaceAll('-','')
    .replace(/ /gm, '')
    .replace(/[^\d]/g,'')
    // this regExp help to format number from '9967707570' to '+7 (996) 770-75-70'
    // .replace(/^([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})$/,'($1) $2-$3-$4')

  let num = `+7 `

  if (value.length < 7) {
    num = `+7 ${value.substring(0, 3)} ${value.substring(3, 6)}`
  } else if (value.length < 9) {
    num = `+7 ${value.substring(0, 3)} ${value.substring(3, 6)}-${value.substring(6, 8)}`
  } else {
    num = `+7 ${value.substring(0, 3)} ${value.substring(3, 6)}-${value.substring(6, 8)}-${value.substring(8, 10)}`
  }

  // num = `+7 ${val.substring(0, 3)} ${val.substring(3, 6)}-${val.substring(6, 8)}-${val.substring(8, val.length)}`
  
  num = num.trim()

  return num
}

export const requiredCheck = (formName, array) => {
  const requiredShow = array.filter(item => item.field === formName)
  if (requiredShow.length !== 0) {
    return true
  }

  return false
}

// Function set Cookies
export const setCookie = (name, value, expires = "01-Jan-2030 00:00:00 GMT", path = "/", domain, secure) => {
  document.cookie = name + "=" + escape(value) +
      ((expires) ? "; expires=" + expires : "") +
      ((path) ? "; path=" + path : "") +
      ((domain) ? "; domain=" + domain : "") +
      ((secure) ? "; secure" : "")
}

// Function get Cookies
export const getCookie = (name) => {
  var cookie = " " + document.cookie
  var search = " " + name + "="
  var setStr = null
  var offset = 0
  var end = 0
  if (cookie.length > 0) {
      offset = cookie.indexOf(search)
      if (offset != -1) {
          offset += search.length
          end = cookie.indexOf(";", offset)
          if (end == -1) {
              end = cookie.length
          }
          setStr = unescape(cookie.substring(offset, end))
      }
  }
  return(setStr)
}

export const getHighlightPositions = (value, text) => {
  // <strong className="highlighted-text">{children}</strong>
  let currentPos = 0

  // remove whitespace dublicates
  value = value
    .replace('  ',' ')
    .replace('  ',' ')
    .replace('  ',' ')

  let arrayValues = value.split(' '),
      highlightsArray = [],
      newText = text

  if (value === '') return highlightsArray

  // search string pos in each word
  for (let item of arrayValues) {
    if (item !== ' ') {
      let startPos = text.toLowerCase().indexOf(item.toLowerCase(),currentPos)
      // console.log('startPos:',startPos)
      // if (startPos !== -1) highlightsArray.push([startPos,item.length])
      if (startPos !== -1) {
        // костыль чтобы убрать массив [0,0] не знаю откуда он берется
        if (startPos+item.length !== 0) {
          highlightsArray.push([startPos,startPos+item.length])
        }
        // currentPos = startPos+item.length
      }
    }
  }

  // to highlight text
  // for (let item of highlightsArray) {
  //   const strongText = <strong className="highlighted-text">{text.slice(item[0],item[1])}</strong>
  //   newText = strongText
  // }

  // return highlightsArray.length !== 0 
  //   ? newText
  //   : text

  highlightsArray
    .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
    // .sort((a, b) => a[0] - b[0])
    // .sort((a, b) => {
    //   if (a[0] === b[0]) {
    //     return a[1] - b[1]
    //   }
    //   return b[0] - a[0]
    // })

  // console.log('highlightsArray:',highlightsArray)

  return highlightsArray
}

export const getArrayStringsHighlighted = (text, array) => {
  let arrayStringsHighlighted = [],
      currentPos = 0
  const stringLength = text.length

  // надо разбить строку на массив используя текущие куски подсветки

  // for (let charIndex = 0; charIndex < stringLength; charIndex++) {
  //   const result = array
  //     .find((element) => {
  //       if(charIndex === element[0]){
  //         console.log('charIndex:',charIndex,'element:',element)
  //         return element
  //       }
  //     })

  //   // console.log('result:',result)
  // }

  for (let item of array) {
    // let text = `${fullName}${suburb && ` (${suburb})`}`
    if (item[0] === currentPos) {
      arrayStringsHighlighted.push(
        {
          text: text.slice(currentPos,item[1]),
          hl: true
        }
      )
      // currentPos = item[1]+1
      currentPos = item[1]
    } else {
      arrayStringsHighlighted.push(
        // text.slice(currentPos,item[0]-1)
        {
          text: text.slice(currentPos,item[0]),
          hl: false
        }
      )
      arrayStringsHighlighted.push(
        {
          text: text.slice(item[0],item[1]),
          hl: true
        }
      )
      // currentPos = item[1]+1
      currentPos = item[1]
    }
  }

  if (currentPos !== stringLength) {
    arrayStringsHighlighted.push(
      {
        text: text.slice(currentPos,stringLength),
        hl: false
      }
    )
  }

  // console.log(array,arrayStringsHighlighted,currentPos)

  return arrayStringsHighlighted
}

export const getSuggestions = (value,loadingResult,previousStreets,streets,sampleData) => {
  let result = []

  if (loadingResult) {
    if (previousStreets && previousStreets.length !== 0) {
      result = previousStreets
    } else {
      // result = sampleData?.suggestionsStreets
      result = sampleData?.streets
    }
  } else {
    if (value.length > 12) {
      if (streets.length === 0) {
        result = []
      } else {
        result = streets
      }
    } else if (value.length <= 12) {
      if (streets.length === 0) {
        // result = sampleData?.suggestionsStreets
        result = sampleData?.streets
      } else {
        result = streets
      }
    }
  }

  return result
}

export const getItemsLength = (searchResult,cachedData,value) => {
  // let itemsLength = 0

  if (searchResult) {
    if (searchResult?.suggestionsStreets.length !== 0) {
      return searchResult?.suggestionsStreets.length-1
    }
  }

  if (cachedData) {
    if (cachedData?.current?.suggestionsStreets.length !== 0) {
      return cachedData?.current?.suggestionsStreets.length-1
    }
  }

  if (value.length <= 12) {
    return 6
  }

  return 0
}