import React from 'react'
import HelmetWrapper from '../components/HelmetWrapper'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function StaticPage({ data, title = '', content = '', isMobile = false }) {
  const { device, cities, navbar, restaurant } = data

  return (
    <>
      <HelmetWrapper 
        title={title}
        phone={restaurant.phone}
      />
      <Header 
        cities={cities}
        navbar={navbar}
        restaurant={restaurant}
        device={device}
        locale={device.locale}
        isMobile={isMobile}
      />
      <div className="static-page-container">
        <div className="static-page-title">
          <h1>{title}</h1>
        </div>
        <div className="static-page-body">
          {content}
        </div>
      </div>
      <Footer
        device={device}
        locale={device.locale} 
        social={restaurant.social} 
      />
    </>
  )
}
