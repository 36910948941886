import React from 'react'
import Button from './Button'
import { useQuery } from '@apollo/client'
import { GET_CART } from '../queries'

export default function CartButton({ customStyle='branded' }) {
  const { loading, error, data } = useQuery(GET_CART)

  if (loading) return <Button customStyle={customStyle}>Корзина</Button>
  if (error) return <Button customStyle={customStyle}>Корзина</Button>
  if (!data) return <Button customStyle={customStyle}>Корзина</Button>

  const { cart } = data
  const { totalItems = 0, grandTotal } = cart

  if (totalItems === 0) {
    return <Button customStyle={customStyle}>Корзина</Button>
  }

  return <Button customStyle={customStyle}>{`${totalItems} | ${grandTotal.formatted}`}</Button>
}
