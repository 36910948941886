import React from 'react'
import { localizeString } from '../shared/functions'

export default function ProductSize({
  type,
  index,
  size,
  selectedSize,
  handle,
  locale
}) {
  const { diameter, amount, capacity } = size

  const handler = () => {
    handle &&
    handle(index)
  }

  if (type === 'pizza') return (
      <li
        className={selectedSize === index ? 'active' : ''}
        onClick={handler}
      >{`${diameter} ${localizeString('см','cm',locale)} ${capacity/1000} ${localizeString('кг','kg',locale)}`}</li>
    )

  if (type === 'sushi') return (
    <li
      className={selectedSize === index ? 'active' : ''}
      onClick={handler}
    >{`${amount} ${localizeString('штук','pieces',locale)} ${capacity} ${localizeString('грамм','gram',locale)}`}</li>
  )
}
