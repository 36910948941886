import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_CART } from '../queries'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CartBody from '../components/CartBody'
import OrderBody from '../components/OrderBody'
import HelmetWrapper from '../components/HelmetWrapper'
import { localizeString } from '../shared/functions'

export default function CartPage({ initialCartID, data }) {
  const { device, cities, navbar, products, restaurants, restaurant } = data
  const { locale } = device

  return (
    <>
      <HelmetWrapper 
        title={localizeString('Корзина','Cart', locale)}
        phone={restaurant.phone}
      />
      {/* <Helmet>
        <title>{'Корзина - ' + HELMET_TITLE}</title>
        <meta name="description" content={META_DESCRIPTION} />
        <meta name="keywords" content={META_KEYWORDS}></meta>
        <meta name="og:title" content="Пятница Пицца - Доставка пиццы в Тамбове домой и в офис" />
        <meta name="og:description" content="Доставка горячей пиццы в вашем городе. Большая и дешевая, бесплатная доставка." />
        <meta name="og:image" content="https://fridaypizza.ru/static/images/banners/645dd33c365c8c5bfd65228c83f4ad18.jpg" />
        <meta name="og:phone_number" content={restaurant.phone} />
      </Helmet> */}
      <Header 
        cities={cities}
        navbar={navbar}
        restaurant={restaurant}
        device={device}
        locale={locale}
      />
      <Wrapper 
        device={device}
        cities={cities}
        restaurants={restaurants}
        restaurant={restaurant}
        products={products}
        initialCartID={initialCartID}
        isMobile={false}
      />
      <Footer
        device={device}
        locale={locale} 
        social={restaurant.social} 
      />
    </>
  )
}

const Wrapper = ({ device, cities, restaurants, restaurant, products, initialCartID, isMobile }) => {
  const { data } = useQuery(GET_CART)
  const { city: cityAlias } = useParams()
  const currentCity = cities.find((city) => city.alias === cityAlias)
  // Filter restaurants by city
  const filteredRestaurants = restaurants.filter(r => r.city.id === currentCity.id)
  const [customerAddress, setCustomerAddress] = React.useState({
    street: '',
    house: '',
    coordinates: null,
  })
  // restaurantId from deliveryCost
  const [restaurantIdFromDeliveryCost, setRestaurantIdFromDeliveryCost] = React.useState(null)

  const handleCustomerAddress = (newValue) => {
    setCustomerAddress({
      ...customerAddress,
      ...newValue,
    })
  }

  const handleRestaurantIdFromDeliveryCost = (newValue) => {
    setRestaurantIdFromDeliveryCost(newValue)
  }

  return (
    <>
      {
        data &&
        data.cart &&
        <>
          <CartBody
            isMobile={isMobile}
            cart={data.cart}
            device={device}
            products={products}
            initialCartID={initialCartID}
            customerAddress={customerAddress}
            currentCity={currentCity}
            handleRestaurantIdFromDeliveryCost={handleRestaurantIdFromDeliveryCost}
          />
          <OrderBody
            isMobile={isMobile}
            cities={cities}
            cart={data.cart}
            device={device}
            restaurants={filteredRestaurants}
            restaurantId={restaurant.id}
            restaurant={restaurant}
            handleCustomerAddress={handleCustomerAddress}
            restaurantIdFromDeliveryCost={restaurantIdFromDeliveryCost}
          />
        </>
      }
    </>
  )
}

// console.log('networkStatus: ', networkStatus)
// if (loading) return <Loading />
// if (error) return <p>{`Error! ${error.message}`}</p>
// if (!data) return <p>Not found</p>
